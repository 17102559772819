export function CalculateText(text) {
    var wrdsTimeSlow = 0;
    var wrdsTimeFast = 0;
    var wrdsTimeOpt = 0;
    var wrdsCount = 0;

    var koefSlow = 1.2;
    var koefFast = 0.85;
    var koefOpt = 1;

    wrdsCount = precalculate(text);

    wrdsTimeSlow = Math.floor((wrdsCount / 2) * koefSlow);
    wrdsTimeFast = Math.floor((wrdsCount / 2) * koefFast);
    wrdsTimeOpt = Math.floor((wrdsCount / 2) * koefOpt);

    return {
        words_count: wrdsCount,
        time_slow: wrdsCount ? Math.max(wrdsTimeSlow, 1) : wrdsTimeSlow,
        time_fast: wrdsCount ? Math.max(wrdsTimeFast, 1) : wrdsTimeFast,
        time_optimal: wrdsCount ? Math.max(wrdsTimeOpt, 1) : wrdsTimeOpt,
    }
}

function isNumber(str) {
    var re = /^[0-9]*$/;
    if (!re.test(str))
        return false;
    else
        return true;
}

function strreplace(strObj, need) {
    var re = new RegExp(need, "g");
    var newstrObj = strObj.replace(re, " ");

    return newstrObj;
}

function strcount(strObj, need, count) {
    var index = 0;
    var indexstart = 0;

    while (index != -1) {
        index = strObj.indexOf(need, indexstart);
        if (index >= 0) {
            indexstart = index + 1;
            count++;
        }
    }
    return count;
}

function precalculate(text) {
    var txtObj = text
    var re = new RegExp('\n', "g"); txtObj = txtObj.replace(re, " ");
    var re = new RegExp('\r', "g"); txtObj = txtObj.replace(re, " ");
    var tarray = txtObj.split(" ");
    var wrdsCount = 0;
    var index = 0;
    var indexstart = 0
    var ltrsCount = 0;

    if (txtObj == '') return 0;

    wrdsCount = strcount(txtObj, "№", wrdsCount);
    txtObj = strreplace(txtObj, "№");
    wrdsCount = strcount(txtObj, "@", wrdsCount);
    txtObj = strreplace(txtObj, "@");
    wrdsCount = strcount(txtObj, "$", wrdsCount);
    txtObj = strreplace(txtObj, "$");
    wrdsCount = strcount(txtObj, "%", wrdsCount);
    txtObj = strreplace(txtObj, "%");
    wrdsCount = strcount(txtObj, "&", wrdsCount);
    txtObj = strreplace(txtObj, "&");
    wrdsCount = strcount(txtObj, "Є", wrdsCount);
    txtObj = strreplace(txtObj, "Є");
    wrdsCount = strcount(txtObj, " пр.", wrdsCount);
    txtObj = strreplace(txtObj, " пр\\.");
    wrdsCount = strcount(txtObj, " ул.", wrdsCount);
    txtObj = strreplace(txtObj, " ул\\.");
    wrdsCount = strcount(txtObj, " г.", wrdsCount);
    txtObj = strreplace(txtObj, " г\\.");
    wrdsCount = strcount(txtObj, " т.", wrdsCount);
    txtObj = strreplace(txtObj, " т\\.");
    wrdsCount = strcount(txtObj, " д.", wrdsCount);
    txtObj = strreplace(txtObj, " д\\.");
    wrdsCount = strcount(txtObj, " кв.", wrdsCount);
    txtObj = strreplace(txtObj, " кв\\.");
    wrdsCount = strcount(txtObj, " кг.", wrdsCount);
    txtObj = strreplace(txtObj, " кг\\.");
    wrdsCount = strcount(txtObj, " см.", wrdsCount);
    txtObj = strreplace(txtObj, " см\\.");
    wrdsCount = strcount(txtObj, " гр.", wrdsCount);
    txtObj = strreplace(txtObj, " гр\\.");

    wrdsCount = strcount(txtObj, "...", wrdsCount);
    txtObj = strreplace(txtObj, "\\.\\.\\.");
    // console.log(tarray)
    for (const el in tarray) {
        if (!el) continue

        if (isNumber(tarray[el])) {
            wrdsCount += tarray[el].length;
            continue;
        }

        if (isNumber(el) & (tarray[el] != "")) {
            const tmpStrArr = tarray[el].split("");
            for (const tmpel in tmpStrArr) {
                if (isNumber(tmpStrArr[tmpel])) {
                    //alert(wrdsCount);
                    wrdsCount += 1;
                    continue;
                }
            }
        }

        txtObj = strreplace(txtObj, '0');
        txtObj = strreplace(txtObj, '1');
        txtObj = strreplace(txtObj, '2');
        txtObj = strreplace(txtObj, '3');
        txtObj = strreplace(txtObj, '4');
        txtObj = strreplace(txtObj, '5');
        txtObj = strreplace(txtObj, '6');
        txtObj = strreplace(txtObj, '7');
        txtObj = strreplace(txtObj, '8');
        txtObj = strreplace(txtObj, '9');
    }

    //------------------------------------------------------------------------------  {
    var txarray = txtObj.split(" ");
    for (const el in txarray) {
        var sElement = txarray[el].toString();
        index = sElement.indexOf('.', 0);
        if (index >= 1 && index < (sElement.length - 1)) {
            const tmpStrArr2 = sElement.split('.');
            wrdsCount += tmpStrArr2.length;
            wrdsCount += tmpStrArr2.length - 1;
        }
        else {
            if (sElement.length > 2 && sElement.length <= 14) wrdsCount += 1;
            if (sElement.length > 14) wrdsCount += 2;
            if ((sElement.length < 3) && (sElement != "-") && (sElement != " ")
                && (sElement != ".") && (sElement != ",") && (sElement != "№")
                && (sElement != "@") && (sElement != "Є") && (sElement != "$")
                && (sElement != "%") && (sElement != "&") && (sElement != "")) {
                ltrsCount++;
            }
        }
    }
    //------------------------------------------------------------------------------  }

    if (ltrsCount > 0) wrdsCount += Math.ceil(ltrsCount / 4);
    wrdsCount = wrdsCount.toFixed(0);

    if (txtObj == '') wrdsCount = 0;
    return wrdsCount;
}

