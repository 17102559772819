
import { mapGetters, mapMutations } from 'vuex'
import * as ov from '@/constants/constants'

export default {
    methods: {
        onChangeLocale(locale_code) {
            // console.log('change')
            this.$refs.langswitcher.classList.remove('open')
            this.$i18n.setLocale(locale_code)
            this.$store.dispatch('getAppinfo')

        },
        onLangSwitcherTouchend(data) {
            // console.log(window.screen.width)
            // console.log(data)
            if (window.screen.width < 1220) {
                // this.$refs.langswitcher.classList.toggle('open')
                data.target.parentElement.classList.toggle('open')
            }
        }
    },

    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser', ]),

        ov() {
            return ov
        },
        year() {
            var now = new Date()
            return now.getFullYear()
        },
    },

}
