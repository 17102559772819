export default function ({ context, app, isServer, route, store, isDev }) {

  // console.log(route)

      //   // редирект на заказы, если авторизован
      //   if (store.getters.isAuthenticated) {
      //     app.router.push(app.localePath('profile-orders-current'))
      //     return false
      // }

    // let version = route.query._storyblok || isDev ? 'draft' : 'published'
    // let language = route.params.language || 'en'
  
    // if (isServer) {
    //   store.commit('setCacheVersion', app.$storyapi.cacheVersion)
    // }
    // console.log('middleware=setAppinfo');
    // console.log(store.state);


    
    // if (!store.state.appinfo) {
    //   // console.log('no appinfo');
    //   store.dispatch('getAppinfo')
    // }
    // else {
    //   // console.log('appinfo exists');
    // }



    // if (!store.state.settings._uid || language !== store.state.language) {
    //   store.commit('setLanguage', language)
  
    //   return store.dispatch('loadSettings', {version: version, language: language})
    // }
  }