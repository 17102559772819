
import { mapGetters, mapMutations } from 'vuex'
import * as ov from '@/constants/constants'

export default {
    data() {
        return {
            modal_state: false,
            modal_name: 'narrators',

            screen: null,

            genres: null,
            genders: null,
            ages: null,
            languages: null,

            narrators: null,
            page: 1,
            total_pages: null,

            narrator: null,

            active_genre: 0,
            filters_shown: 0,
            filter_genders: [],
            filter_ages: [],
            filter_language: 0,
            filter_native: false,
            filter_featured: 0,
            filter_topic: 0,

            narratorcomp: null,
            url_copied: false,

            filter_sort: 0,

            timerBeforeGetNarratorsPopup: null,
            timerBeforeGetNarratorcompsPopup: null,

            var_name: '',
        }
    },

    methods: {
        closeModal() {
            this.$root.$emit('close-all-modals')
        },

        setScreen(index) {
            if ((index == 2) && (this.screen != index)) {
                this.$root.$emit('set_filter_featured', 1)
            }
            if ((index == 1) && (this.screen != index)) {
                this.$root.$emit('set_filter_featured', 0)
            }

            // если переходим из подборки в дикторов или избранных, то сбрасываем жанр, иначе нет
            if ([1, 2].includes(index) && ![1, 2].includes(this.screen)) {
                this.active_genre = 0
                this.$root.$emit('set_active_genre', this.active_genre)
            }

            this.screen = index
            this.filter_sort = 0 //сбрасываем сортировку



            if (this.screen == 4) {
                this.active_genre = 0//this.narratorcomp.genres_id
                this.$root.$emit('set_active_genre', this.active_genre)
            }
            // if (this.screen == 1) {
            //     this.active_genre = 0
            //     this.$root.$emit('set_active_genre', this.active_genre)
            // }
        },

        onUpdateFilter(data) {
            this.page = 1
            this.active_genre = data.filter_genre
            this.filter_genders = data.filter_genders
            this.filter_ages = data.filter_ages
            this.filter_language = data.filter_language
            this.active_genre = data.filter_genre
            this.filter_featured = this.screen == 2 ? 1 : 0//data.filter_featured
            this.filter_topic = data.filter_topic
            this.filter_timbres = data.filter_timbres
            this.filter_native = data.filter_native

            this.getItems()
        },

        getItems() {
            if (this.screen == 4) {
                this.getNarratorcompNarrators()
            }
            else {
                this.getNarrators()
            }
        },

        getNarrators() {
            clearTimeout(this.timerBeforeGetNarratorsPopup);
            this.timerBeforeGetNarratorsPopup = setTimeout(() => {
                this.$axios
                    .get('/narrators', {
                        params: {
                            page: this.page,
                            genre: this.active_genre,
                            gender: this.filter_genders,
                            age: this.filter_ages,
                            topic: this.filter_topic,
                            timbre: this.filter_timbres,
                            language: this.filter_language,
                            native: + this.filter_native,
                            featured: + this.filter_featured,
                            sort: + this.filter_sort,
                            name: this.var_name,
                        }
                    })
                    .then((res) => {
                        if (this.page > 1) {
                            this.narrators = this.narrators.concat(res.data.narrators)
                        }
                        else this.narrators = res.data.narrators

                        this.total_pages = res.data.total_pages
                    })
            }, 500)

        },

        getNarratorcompNarrators() {
            clearTimeout(this.timerBeforeGetNarratorcompsPopup);
            this.timerBeforeGetNarratorcompsPopup = setTimeout(() => {
                this.$axios
                    .get('/narratorcomps/id/' + this.narratorcomp.id, {
                        params: {
                            page: this.page,
                            genre: this.active_genre,
                            gender: this.filter_genders,
                            age: this.filter_ages,
                            topic: this.filter_topic,
                            timbre: this.filter_timbres,
                            language: this.filter_language,
                            native: + this.filter_native,
                            sort: + this.filter_sort,
                        }
                    })
                    .then((res) => {
                        if (this.page > 1) {
                            this.narratorcomp.narrators = this.narratorcomp.narrators.concat(res.data.narratorcomp.narrators)
                        }
                        else this.narratorcomp.narrators = res.data.narratorcomp.narrators

                        this.total_pages = res.data.total_pages
                    })
            }, 500)
        },

        toOrder(genre, subgenre = null) {
            this.$store.commit('setOrderGenre', genre)
            this.selectNarrator()
        },

        openReviews() {
            this.setScreen(6)
        },

        selectNarrator() {
            this.$store.dispatch('setOrderNarrator', this.narrator.id).then(() => {
                this.$root.$emit('close-all-modals')
            })
        },

        copyLink(value = '') {
            let copyUrlBtn = document.querySelector('.copyToClipBoardBtn');

            let tempInput = document.createElement('textarea');

            tempInput.style.fontSize = '12pt';
            tempInput.style.border = '0';
            tempInput.style.padding = '0';
            tempInput.style.margin = '0';
            tempInput.style.position = 'absolute';
            tempInput.style.left = '-9999px';
            tempInput.setAttribute('readonly', '');

            tempInput.value = value ? value : window.location.href;

            copyUrlBtn.parentNode.appendChild(tempInput);

            tempInput.select();
            tempInput.setSelectionRange(0, 99999);

            document.execCommand('copy');

            tempInput.parentNode.removeChild(tempInput);

            this.url_copied = true
        },
    },

    mounted() {
        this.$root.$on('open-modal', (data) => {
            if (data.modal == this.modal_name) {
                this.$axios
                    .get('/narrators', {
                        params: {}
                    })
                    .then((res) => {
                        this.narrators = res.data.narrators
                        this.total_pages = res.data.total_pages

                        this.genres = res.data.genres
                        this.genders = res.data.genders
                        this.ages = res.data.ages
                        this.languages = res.data.languages
                    })

                this.setScreen(1)
                this.modal_state = true
                this.$nextTick(() => {
                    window.refreshPage()
                })
            }
        })

        this.$root.$on('close-all-modals', () => {
            this.modal_state = false
            this.active_genre = 0
        })

        this.$root.$on('show-narrator', (data) => {
            this.$axios
                .get('/narrators/id/' + data.id, {
                    params: {}
                })
                .then((res) => {
                    this.narrator = res.data.narrator
                    this.setScreen(5)
                    this.$nextTick(() => {
                        window.refreshPage()
                    })

                })

        })

        this.$root.$on('show-narratorcomp', (data) => {
            this.$axios
                .get('/narratorcomps/id/' + data.id, {
                    params: {}
                })
                .then((res) => {
                    this.narratorcomp = res.data.narratorcomp
                    this.setScreen(4)
                })

        })
    },

    beforeDestroy() {
        this.$root.$off('open-modal')
        this.$root.$off('close-all-modals')
        this.$root.$off('show-narrator')
        this.$root.$off('show-narratorcomp')
    },

    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser']),
        ov() {
            return ov
        },
        narratorImage() {
            return this.narrator.image ? this.narrator.image : ov.DEFAULT_IMAGE
        },

        isClient() {
            return this.isAuthenticated && (this.loggedInUser.role == 'client')
        },

        long_c_perc() {
            return 100 - Math.ceil((+this.narrator.prices.long_c_5p / +this.narrator.prices.long_c_1p) * 100)
        },

        notNarrator() {
            return !this.isAuthenticated || (this.isAuthenticated && (this.loggedInUser.role == 'client'))
        },
        selectedDays() {
            var arr = Object.values(this.narrator.timetable)
            delete arr[0]
            return (arr.findIndex(item => item) + 1)
        },
    },

}      
