
import { mapGetters } from 'vuex'

export default {
    props: [
        'checklist',
    ],

    data() {
        return {
            narrator: null,
            ready_to_view: false,
            main_uploading: false,
        }
    },

    methods: {
        closeModal() {
            this.$root.$emit('close-all-modals')
        },

        openFileSelect(is_main = false) {
            if (is_main) this.main_uploading = true; else this.main_uploading = false
            var el = this.$refs.demo_upload
            el.click()
        },

        uploadDemo() {
            var formData = new FormData();
            if (typeof this.$refs.demo_upload.files[0] === 'undefined') return false
            if (this.main_uploading) formData.append('main', 1)
            formData.append("file", this.$refs.demo_upload.files[0])

            this.$axios
                .post('/narrator/demos',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                )
                .then((res) => {
                    res.data.demo.opened = true
                    this.narrator.demos.push(res.data.demo)
                    this.$root.$emit('refresh-narrator')
                })         
        },

        deleteDemo(id) {
            this.$axios
                .delete('/narrator/demos/' + id,
                    {}
                )
                .then((res) => {
                    var index = this.narrator.demos.findIndex(item => item.id == id)
                    this.narrator.demos.splice(index, 1)
                    this.$root.$emit('refresh-narrator')
                })
        },

        saveDemo(data) {
            // console.log('parent save')
            // console.log(data)
            var index = this.narrator.demos.findIndex(item => item.id == data.id)
            // console.log(index)
            Object.assign(this.narrator.demos[index], data);

            this.$axios
                .put('/narrator/demos/'+data.id,
                    {
                        demo: this.narrator.demos[index],
                    }
                )
                .then((res) => {
                    this.$root.$emit('refresh-narrator')
                })
        },

        saveNarrator() {
            this.$axios
                .post('/narrator/finish-register/'+this.narrator.id,
                    {
                    }
                )
                .then((res) => {
                    
                })            
            this.$emit('updated')
        },
    },

    mounted() {
        this.$axios
            .get('/narrator/demos')
            .then((res) => {
                this.narrator = res.data.narrator
                this.$nextTick(function () {
                    this.ready_to_view = true
                })
            })
    },

    computed: {
        ...mapGetters(['loggedInUser', 'appInfo']),

        mainDemoIndex() {
            // console.log(this.narrator.demos.find(item => item.main))
            return this.narrator.demos.findIndex(item => item.main)
        },

        filledMain() {
            return (this.mainDemoIndex + 1)
        },

        filledAd() {
            // console.log(this.narrator.demos.findIndex(item => item.genres_id == 1))
            return this.narrator.demos.findIndex(item => !item.main && (item.genres_id == 1)) + 1
        },

        filledBook() {
            return this.narrator.demos.findIndex(item => !item.main && (item.genres_id == 2)) + 1
        },

        filledLongread() {
            return this.narrator.demos.findIndex(item => !item.main && (item.genres_id == 3)) + 1
        },

        filledFrame() {
            return this.narrator.demos.findIndex(item => !item.main && (item.genres_id == 4)) + 1
        },

        filledJoy() {
            return this.narrator.demos.findIndex(item => !item.main && (item.moods_id == 3)) + 1
        },

        filledSad() {
            return this.narrator.demos.findIndex(item => !item.main && (item.moods_id == 1)) + 1
        },

        filledAll() {
            return (this.filledAd
                || this.filledBook
                || this.filledLongread
                || this.filledFrame
                || this.filledJoy
                || this.filledSad)
                && this.filledMain
        },

        demosExist() {
            return ((this.narrator.demos.length == 1) && !this.narrator.demos[0].main)
            || this.narrator.demos.length > 1
        },
    }
}    
