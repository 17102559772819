
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            hours: null,
        }
    },

    computed: {
        ...mapGetters(['loggedInUser'])
    },

    methods: {
        closeModal() {
            this.$root.$emit('close-all-modals')
        },

        setPause() {
            this.$axios
                .post('/narrator/set-pause',
                    {
                        hours: this.hours,
                    },
                )
                .then((res) => {
                    this.$store.commit('setPauseHours', this.hours)
                    this.$store.commit('setPauseLeft', this.hours * 60 * 60)
                    this.closeModal()
                    this.$root.$emit('getAppState')
                })
        },

    },

    mounted() {
        this.hours = this.loggedInUser.pause_hours
    },
}    
