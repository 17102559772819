
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            modal_state: false,
            modal_name: 'login',
            role: '',
        }
    },

    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser']),
    },

    mounted() {
        this.$root.$on('open-modal', (data) => {
            if (data.modal == this.modal_name) {
                this.modal_state = true
                this.role = data.role
            }
        })

        this.$root.$on('close-all-modals', () => {
            this.modal_state = false
        })
    },

    beforeDestroy() {
        this.$root.$off('open-modal')
        this.$root.$off('close-all-modals')
    },

    methods: {
        closeModal() {
            this.$root.$emit('close-all-modals')
        },
        successLogin(data) {
            this.closeModal()
            if (data.role == 'agent') {
                this.$root.$emit('open-modal', { modal: 'AgentNarratorChange', closeable: false })
                // this.$router.push(this.localePath('profile-settings'))
            } else {
                this.$router.push(this.localePath('profile-orders'))
            }
        },
        goRegister() {
            this.closeModal()
            this.$root.$emit('open-modal', { modal: 'register', step: 2 })
        },
    },
}    
