import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _31637b76 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _0b6389f8 = () => interopDefault(import('../pages/become-narrator.vue' /* webpackChunkName: "pages/become-narrator" */))
const _ee8009e8 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _0d05540c = () => interopDefault(import('../pages/compilations/index.vue' /* webpackChunkName: "pages/compilations/index" */))
const _511b563b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _9800f130 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _506bf894 = () => interopDefault(import('../pages/narrators/index.vue' /* webpackChunkName: "pages/narrators/index" */))
const _cc9e6b6c = () => interopDefault(import('../pages/new-order.vue' /* webpackChunkName: "pages/new-order" */))
const _15b71a76 = () => interopDefault(import('../pages/offer.vue' /* webpackChunkName: "pages/offer" */))
const _21156b85 = () => interopDefault(import('../pages/out-of-service.vue' /* webpackChunkName: "pages/out-of-service" */))
const _0329397e = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _2d440455 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _59b3706e = () => interopDefault(import('../pages/compilations/genre/index.vue' /* webpackChunkName: "pages/compilations/genre/index" */))
const _1c110dc7 = () => interopDefault(import('../pages/narrators/filter/index.vue' /* webpackChunkName: "pages/narrators/filter/index" */))
const _1557df75 = () => interopDefault(import('../pages/profile/orders/index.vue' /* webpackChunkName: "pages/profile/orders/index" */))
const _2d4f345e = () => interopDefault(import('../pages/profile/reviews.vue' /* webpackChunkName: "pages/profile/reviews" */))
const _a5761120 = () => interopDefault(import('../pages/profile/settings.vue' /* webpackChunkName: "pages/profile/settings" */))
const _35acf715 = () => interopDefault(import('../pages/profile/support/index.vue' /* webpackChunkName: "pages/profile/support/index" */))
const _372c84f4 = () => interopDefault(import('../pages/profile/wallet.vue' /* webpackChunkName: "pages/profile/wallet" */))
const _3194b1dc = () => interopDefault(import('../pages/profile/orders/current.vue' /* webpackChunkName: "pages/profile/orders/current" */))
const _1b6188df = () => interopDefault(import('../pages/profile/orders/drafts.vue' /* webpackChunkName: "pages/profile/orders/drafts" */))
const _047bbfc6 = () => interopDefault(import('../pages/profile/orders/ready.vue' /* webpackChunkName: "pages/profile/orders/ready" */))
const _2811a2ed = () => interopDefault(import('../pages/profile/support/tickets/index.vue' /* webpackChunkName: "pages/profile/support/tickets/index" */))
const _167f7906 = () => interopDefault(import('../pages/profile/support/tickets/_ticket.vue' /* webpackChunkName: "pages/profile/support/tickets/_ticket" */))
const _fec0a0d0 = () => interopDefault(import('../pages/compilations/genre/_genre.vue' /* webpackChunkName: "pages/compilations/genre/_genre" */))
const _6a025ccf = () => interopDefault(import('../pages/narrators/filter/_category1.vue' /* webpackChunkName: "pages/narrators/filter/_category1" */))
const _73402636 = () => interopDefault(import('../pages/narrators/filter/_category1/_category2.vue' /* webpackChunkName: "pages/narrators/filter/_category1/_category2" */))
const _d78aaae0 = () => interopDefault(import('../pages/narrators/filter/_category1/_category2/_category3.vue' /* webpackChunkName: "pages/narrators/filter/_category1/_category2/_category3" */))
const _08b47c88 = () => interopDefault(import('../pages/profile/orders/_order.vue' /* webpackChunkName: "pages/profile/orders/_order" */))
const _34323b5a = () => interopDefault(import('../pages/blog/_category/index.vue' /* webpackChunkName: "pages/blog/_category/index" */))
const _65adb21f = () => interopDefault(import('../pages/compilations/_narratorcomp.vue' /* webpackChunkName: "pages/compilations/_narratorcomp" */))
const _34094e4c = () => interopDefault(import('../pages/narrators/_narrator.vue' /* webpackChunkName: "pages/narrators/_narrator" */))
const _d24f74f2 = () => interopDefault(import('../pages/blog/_category/_post.vue' /* webpackChunkName: "pages/blog/_category/_post" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _31637b76,
    pathToRegexpOptions: {"strict":true},
    name: "about___ru"
  }, {
    path: "/become-narrator",
    component: _0b6389f8,
    pathToRegexpOptions: {"strict":true},
    name: "become-narrator___ru"
  }, {
    path: "/blog",
    component: _ee8009e8,
    pathToRegexpOptions: {"strict":true},
    name: "blog___ru"
  }, {
    path: "/compilations",
    component: _0d05540c,
    pathToRegexpOptions: {"strict":true},
    name: "compilations___ru"
  }, {
    path: "/en",
    component: _511b563b,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/help",
    component: _9800f130,
    pathToRegexpOptions: {"strict":true},
    name: "help___ru"
  }, {
    path: "/narrators",
    component: _506bf894,
    pathToRegexpOptions: {"strict":true},
    name: "narrators___ru"
  }, {
    path: "/new-order",
    component: _cc9e6b6c,
    pathToRegexpOptions: {"strict":true},
    name: "new-order___ru"
  }, {
    path: "/offer",
    component: _15b71a76,
    pathToRegexpOptions: {"strict":true},
    name: "offer___ru"
  }, {
    path: "/out-of-service",
    component: _21156b85,
    pathToRegexpOptions: {"strict":true},
    name: "out-of-service___ru"
  }, {
    path: "/privacy-policy",
    component: _0329397e,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___ru"
  }, {
    path: "/profile",
    component: _2d440455,
    pathToRegexpOptions: {"strict":true},
    name: "profile___ru"
  }, {
    path: "/compilations/genre",
    component: _59b3706e,
    pathToRegexpOptions: {"strict":true},
    name: "compilations-genre___ru"
  }, {
    path: "/en/about",
    component: _31637b76,
    pathToRegexpOptions: {"strict":true},
    name: "about___en"
  }, {
    path: "/en/become-narrator",
    component: _0b6389f8,
    pathToRegexpOptions: {"strict":true},
    name: "become-narrator___en"
  }, {
    path: "/en/blog",
    component: _ee8009e8,
    pathToRegexpOptions: {"strict":true},
    name: "blog___en"
  }, {
    path: "/en/compilations",
    component: _0d05540c,
    pathToRegexpOptions: {"strict":true},
    name: "compilations___en"
  }, {
    path: "/en/help",
    component: _9800f130,
    pathToRegexpOptions: {"strict":true},
    name: "help___en"
  }, {
    path: "/en/narrators",
    component: _506bf894,
    pathToRegexpOptions: {"strict":true},
    name: "narrators___en"
  }, {
    path: "/en/new-order",
    component: _cc9e6b6c,
    pathToRegexpOptions: {"strict":true},
    name: "new-order___en"
  }, {
    path: "/en/offer",
    component: _15b71a76,
    pathToRegexpOptions: {"strict":true},
    name: "offer___en"
  }, {
    path: "/en/out-of-service",
    component: _21156b85,
    pathToRegexpOptions: {"strict":true},
    name: "out-of-service___en"
  }, {
    path: "/en/privacy-policy",
    component: _0329397e,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___en"
  }, {
    path: "/en/profile",
    component: _2d440455,
    pathToRegexpOptions: {"strict":true},
    name: "profile___en"
  }, {
    path: "/narrators/filter",
    component: _1c110dc7,
    pathToRegexpOptions: {"strict":true},
    name: "narrators-filter___ru"
  }, {
    path: "/profile/orders",
    component: _1557df75,
    pathToRegexpOptions: {"strict":true},
    name: "profile-orders___ru"
  }, {
    path: "/profile/reviews",
    component: _2d4f345e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reviews___ru"
  }, {
    path: "/profile/settings",
    component: _a5761120,
    pathToRegexpOptions: {"strict":true},
    name: "profile-settings___ru"
  }, {
    path: "/profile/support",
    component: _35acf715,
    pathToRegexpOptions: {"strict":true},
    name: "profile-support___ru"
  }, {
    path: "/profile/wallet",
    component: _372c84f4,
    pathToRegexpOptions: {"strict":true},
    name: "profile-wallet___ru"
  }, {
    path: "/en/compilations/genre",
    component: _59b3706e,
    pathToRegexpOptions: {"strict":true},
    name: "compilations-genre___en"
  }, {
    path: "/en/narrators/filter",
    component: _1c110dc7,
    pathToRegexpOptions: {"strict":true},
    name: "narrators-filter___en"
  }, {
    path: "/en/profile/orders",
    component: _1557df75,
    pathToRegexpOptions: {"strict":true},
    name: "profile-orders___en"
  }, {
    path: "/en/profile/reviews",
    component: _2d4f345e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reviews___en"
  }, {
    path: "/en/profile/settings",
    component: _a5761120,
    pathToRegexpOptions: {"strict":true},
    name: "profile-settings___en"
  }, {
    path: "/en/profile/support",
    component: _35acf715,
    pathToRegexpOptions: {"strict":true},
    name: "profile-support___en"
  }, {
    path: "/en/profile/wallet",
    component: _372c84f4,
    pathToRegexpOptions: {"strict":true},
    name: "profile-wallet___en"
  }, {
    path: "/profile/orders/current",
    component: _3194b1dc,
    pathToRegexpOptions: {"strict":true},
    name: "profile-orders-current___ru"
  }, {
    path: "/profile/orders/drafts",
    component: _1b6188df,
    pathToRegexpOptions: {"strict":true},
    name: "profile-orders-drafts___ru"
  }, {
    path: "/profile/orders/ready",
    component: _047bbfc6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-orders-ready___ru"
  }, {
    path: "/profile/support/tickets",
    component: _2811a2ed,
    pathToRegexpOptions: {"strict":true},
    name: "profile-support-tickets___ru"
  }, {
    path: "/en/profile/orders/current",
    component: _3194b1dc,
    pathToRegexpOptions: {"strict":true},
    name: "profile-orders-current___en"
  }, {
    path: "/en/profile/orders/drafts",
    component: _1b6188df,
    pathToRegexpOptions: {"strict":true},
    name: "profile-orders-drafts___en"
  }, {
    path: "/en/profile/orders/ready",
    component: _047bbfc6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-orders-ready___en"
  }, {
    path: "/en/profile/support/tickets",
    component: _2811a2ed,
    pathToRegexpOptions: {"strict":true},
    name: "profile-support-tickets___en"
  }, {
    path: "/",
    component: _511b563b,
    pathToRegexpOptions: {"strict":true},
    name: "index___ru"
  }, {
    path: "/en/profile/support/tickets/:ticket",
    component: _167f7906,
    pathToRegexpOptions: {"strict":true},
    name: "profile-support-tickets-ticket___en"
  }, {
    path: "/en/compilations/genre/:genre",
    component: _fec0a0d0,
    pathToRegexpOptions: {"strict":true},
    name: "compilations-genre-genre___en"
  }, {
    path: "/en/narrators/filter/:category1",
    component: _6a025ccf,
    pathToRegexpOptions: {"strict":true},
    name: "narrators-filter-category1___en",
    children: [{
      path: ":category2?",
      component: _73402636,
      pathToRegexpOptions: {"strict":true},
      name: "narrators-filter-category1-category2___en",
      children: [{
        path: ":category3?",
        component: _d78aaae0,
        pathToRegexpOptions: {"strict":true},
        name: "narrators-filter-category1-category2-category3___en"
      }]
    }]
  }, {
    path: "/en/profile/orders/:order",
    component: _08b47c88,
    pathToRegexpOptions: {"strict":true},
    name: "profile-orders-order___en"
  }, {
    path: "/profile/support/tickets/:ticket",
    component: _167f7906,
    pathToRegexpOptions: {"strict":true},
    name: "profile-support-tickets-ticket___ru"
  }, {
    path: "/compilations/genre/:genre",
    component: _fec0a0d0,
    pathToRegexpOptions: {"strict":true},
    name: "compilations-genre-genre___ru"
  }, {
    path: "/en/blog/:category",
    component: _34323b5a,
    pathToRegexpOptions: {"strict":true},
    name: "blog-category___en"
  }, {
    path: "/en/compilations/:narratorcomp",
    component: _65adb21f,
    pathToRegexpOptions: {"strict":true},
    name: "compilations-narratorcomp___en"
  }, {
    path: "/en/narrators/:narrator",
    component: _34094e4c,
    pathToRegexpOptions: {"strict":true},
    name: "narrators-narrator___en"
  }, {
    path: "/narrators/filter/:category1",
    component: _6a025ccf,
    pathToRegexpOptions: {"strict":true},
    name: "narrators-filter-category1___ru",
    children: [{
      path: ":category2?",
      component: _73402636,
      pathToRegexpOptions: {"strict":true},
      name: "narrators-filter-category1-category2___ru",
      children: [{
        path: ":category3?",
        component: _d78aaae0,
        pathToRegexpOptions: {"strict":true},
        name: "narrators-filter-category1-category2-category3___ru"
      }]
    }]
  }, {
    path: "/profile/orders/:order",
    component: _08b47c88,
    pathToRegexpOptions: {"strict":true},
    name: "profile-orders-order___ru"
  }, {
    path: "/en/blog/:category/:post",
    component: _d24f74f2,
    pathToRegexpOptions: {"strict":true},
    name: "blog-category-post___en"
  }, {
    path: "/blog/:category",
    component: _34323b5a,
    pathToRegexpOptions: {"strict":true},
    name: "blog-category___ru"
  }, {
    path: "/compilations/:narratorcomp",
    component: _65adb21f,
    pathToRegexpOptions: {"strict":true},
    name: "compilations-narratorcomp___ru"
  }, {
    path: "/narrators/:narrator",
    component: _34094e4c,
    pathToRegexpOptions: {"strict":true},
    name: "narrators-narrator___ru"
  }, {
    path: "/blog/:category/:post",
    component: _d24f74f2,
    pathToRegexpOptions: {"strict":true},
    name: "blog-category-post___ru"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
