import * as ov from '@/constants/constants';
import * as fun from '@/functions/textcalc';

export default {

    saveHome(state, value) {
        state.home = value
    },

    updateUserData(state, value) {
        if (!state.auth.user) return
        if (value.orders) state.auth.user.orders = value.orders
        if (value.payments) state.auth.user.payments = value.payments
        if (value.tickets) state.auth.user.tickets = value.tickets
        if (value.balance) state.auth.user.balance = value.balance
        if (typeof value.on_moderate !== 'undefined') state.auth.user.on_moderate = value.on_moderate
        if (value.agent_narrators) state.auth.user.agent_narrators = value.agent_narrators

        if (value.firstname) state.auth.user.name = state.auth.user.firstname = value.firstname
        if (value.lastname) state.auth.user.lastname = value.lastname
        if (value.email) state.auth.user.email = value.email
        if (value.image) state.auth.user.image = value.image
        if (value.genres) state.auth.user.genres = value.genres

        if (value.tg_connected !== undefined) state.auth.user.tg_connected = value.tg_connected
    },




    // управление паузой диктора

    setPauseLeft(state, value) {
        if (state.auth.user) state.auth.user.pause_left = value
    },

    setOnPause(state, value) {
        if (state.auth.user) state.auth.user.on_pause = value
    },

    setPauseHours(state, value) {
        if (state.auth.user) state.auth.user.pause_hours = value
    },

    decreasePauseLeft(state) {
        if (state.auth.user) {
            state.auth.user.pause_left--
        }
    },






    // заполнение нового заказа

    setOrderAll(state, data) {
        state.order = data
    },

    setOrderNarrator(state, value) {
        state.order.narrator = value
    },

    setOrderTitle(state, value) {
        state.order.title = value
    },

    setOrderText(state, value) {
        state.order.text = value
        var arr = fun.CalculateText(value)
        state.order.time_fast = arr.time_fast
        state.order.time_slow = arr.time_slow
        state.order.time_optimal = arr.time_optimal
        state.order.words_count = arr.words_count
    },

    setOrderGenre(state, value) {
        state.order.genre.id = value

        const orderGenre = Object.values(state.appinfo.genres).find(el => el.id === state.order.genre.id)

        if (!([ov.GENRE_AUDIOBOOK].includes(orderGenre.id) || [ov.GENRE_AUDIOBOOK].includes(orderGenre.pricing_genre_id))) {
            state.order.cleaning = false
        }
        if ([ov.GENRE_AUDIOBOOK].includes(orderGenre.id) || [ov.GENRE_AUDIOBOOK].includes(orderGenre.pricing_genre_id)) {
            state.order.cleaning = true
        }
        if ([ov.GENRE_LONGREAD, ov.GENRE_OFFFRAME, ov.GENRE_YOUTUBE].includes(orderGenre.id) || [ov.GENRE_LONGREAD, ov.GENRE_OFFFRAME, ov.GENRE_YOUTUBE].includes(orderGenre.pricing_genre_id)) {
            state.order.mixing = false
            state.order.federal = false
        }
        if ([ov.GENRE_OFFFRAME, ov.GENRE_YOUTUBE].includes(orderGenre.id) || [ov.GENRE_OFFFRAME, ov.GENRE_YOUTUBE].includes(orderGenre.pricing_genre_id)) state.order.cleaning = false

        if (!([ov.GENRE_ADVERT, ov.GENRE_PLAY].includes(orderGenre.id) || [ov.GENRE_ADVERT, ov.GENRE_PLAY].includes(orderGenre.pricing_genre_id))) {
            if (state.order.musics.length) state.order.cleaning = false
            state.order.musics = []
        }
    },

    // setOrderMood(state, value) {
    //   state.order.mood = value
    // },

    setOrderSpeed(state, value) {
        state.order.speed = value
    },

    setOrderTopic(state, value) {
        state.order.topic = value
    },

    setOrderUrgent(state, value) {
        state.order.urgent = value && 1
    },

    setOrderDogovorNeeded(state, value) {
        state.order.dogovor_needed = value && 1
    },

    setOrderFile(state, value) {
        state.order.file = value
    },

    setOrderPrice(state, value) {
        state.order.price = value
    },

    setOrderBasePrice(state, value) {
        state.order.base_price = value
    },

    setOrderCleaningPrice(state, value) {
        state.order.cleaning_price = value
    },

    setOrderMixingPrice(state, value) {
        state.order.mixing_price = value
    },

    setOrderAlienationPrice(state, value) {
        state.order.alienation_price = value
    },

    setOrderFederalPrice(state, value) {
        state.order.federal_price = value
    },

    setOrderPaymentMethod(state, value) {
        state.order.payment_method = value
    },

    addOrderMusic(state, music) {
        state.order.musics.push(music)
        state.order.cleaning = true
        state.order.mixing = ov.MIXING_ENABLED && true
    },

    removeOrderMusic(state, id) {
        var index = state.order.musics.findIndex(item => item.id === id)
        state.order.musics.splice(index, 1)
        if (!state.order.musics.length) {
            state.order.cleaning = false
            state.order.mixing = false
        }
    },

    removeAllOrderMusic(state) {
        state.order.musics = []
    },

    setOrderLink(state, value) {
        state.order.link = value
    },

    setOrderOptions(state, values) {

    },

    toggleOrderOption(state, id) { //смотри одноименный action
        if (id == ov.OPTION_CLEANING) {
            state.order.cleaning = !state.order.cleaning
        }
        if (id == ov.OPTION_MIXING) {
            state.order.mixing = !state.order.mixing
            // state.order.cleaning = state.order.mixing
        }
        if (id == ov.OPTION_ALIENATION) {
            state.order.alienation = !state.order.alienation
        }
        if (id == ov.OPTION_FEDERAL) {
            state.order.federal = !state.order.federal
        }
    },

    setOrderCliplength(state, value) {
        state.order.clip_length = value
    },

    setOrderStatus(state, value) {
        state.order.orderstatuses_id = value
    },

    clearOrderComments(state) {
        state.order.ordermessages = []
    },

    initOrder(state) {
        if (!state.order_protected) state.order = {
            narrator: {
                id: null,
                firstname: null,
                lastname: null,
                image: null,
                urgent: 1,
                prices: {
                    urgent: 50,
                },
                comment: null,
            },
            prevstatus_id: null,

            title: null,
            text: null,

            words_count: 0,
            time_optimal: 0,
            time_slow: 0,
            time_fast: 0,

            genre: {
                id: 1,
            },
            // mood: 2,
            // speed: 2,
            topic: 0,
            urgent: 0,
            price: null,
            file: null,
            file_id: null,
            comment: null,
            musics: [],

            cleaning: false,
            mixing: false,
            alienation: false,
            federal: false,

            clip_length: 0,
        }
    },

    toggleOrderProtectionFromCleaning(state, value) {
        state.order_protected = value
    },

    createOrderComment(state, data) {
        state.order.ordermessages.push(data)
    },

    removeOrderComment(state, id) {
        var index = state.order.ordermessages.findIndex(item => item.id == id)
        if (index + 1) state.order.ordermessages.splice(index, 1)
    },


    //============== остальное

    saveAppinfo(state, value) {
        state.appinfo = value
    },

    saveStateTimer(state, value) {
        state.state_timer = value
    },

    savePauseTimer(state, value) {
        state.pause_timer = value
    },


    new_order_message(state, data) {
        var index = state.order.ordermessages.findIndex(item => item.id == data.id)
        if (!(index + 1)) state.order.ordermessages.push(data)
    },

    delete_order_message(state, data) {
        var index = state.order.ordermessages.findIndex(item => item.id == data.id)
        if (index + 1) state.order.ordermessages.splice(index, 1)
    },

    update_order_message(state, data) {
        var index = state.order.ordermessages.findIndex(item => item.id == data.id)
        // console.log('update_order_message',index)
        // state.order.ordermessages[index] = data
        // state.order.ordermessages[index].text = data.text
        // console.log('before',state.order.ordermessages[index].text)
        // console.log(window.$nuxt)
        if (index + 1) {

            // state.order.ordermessages.splice(index, 1)
            // state.order.ordermessages.splice(index, 1, data)
            window.$nuxt.$set(state.order.ordermessages, index, data)

            // var messages = state.order.ordermessages
            // messages.splice(index, 1, data)
            // state.order.ordermessages = messages

            // window.$nuxt.state.order.ordermessages.splice(index, 1, data)

            // state.order.ordermessages.push(data)
            // state.order.ordermessages.push(data)
            // state.order.ordermessages[index] = data
            // console.log('after',state.order.ordermessages[index].text)
        }
    },

    update_events(state, data) {
        state.events = data
    },

    clear_order_events(state, id) {
        // console.log(id,state.events)
        if (state.events !== null) {
            state.events = state.events.filter(item => !item.order_id || (item.order_id != id))
        }
        // console.log(state.events)
    },

    clear_ticket_events(state, id) {
        // console.log(id,state.events)
        if (state.events !== null) {
            state.events = state.events.filter(item => !item.ticket_id || (item.ticket_id != id))
        }
        // console.log(state.events)
    },


    setReferalUrl(state, url_condition) {
        state.referal_url = url_condition
    }



}