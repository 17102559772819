
import { mapGetters, mapMutations } from 'vuex'
import * as ov from '@/constants/constants'

export default {
    props: [
        'id',
        'firstname',
        'lastname',
        'image',
        "slug",
        'featured',
        'prices',
        'active_genre',
        'main_genre',
        'emitInsteadOpen',
        'related_demo',
        'has_genre',
    ],

    data() {
        return {
            is_featured: this.featured,
        }
    },

    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser', 'appInfo']),

        narratorImage() {
            return this.image ? this.image : ov.DEFAULT_IMAGE
        },

        genrePrice() {
            var genre = +this.active_genre
            if (!genre) genre = (this.main_genre ? this.main_genre.id : 0)

            let base_genre = 0
            const genre_obj = Object.values(this.appInfo.genres).find(el => el.id === genre)
            if (genre_obj) {
                base_genre = genre_obj.pricing_genre_id ?? 0
                // console.log(base_genre)
            }

            if ([genre, base_genre].includes(1) && this.prices && this.prices.ad_30s) return this.$t('upto-30s') + ' – ' + this.prices.ad_30s + ' ₽'
            if ([genre, base_genre].includes(2) && this.prices && this.prices.book_1h) return this.$t('upto-1h') + ' – ' + this.prices.book_1h + ' ₽'
            if ([genre, base_genre].includes(3) && this.prices && this.prices.long_c_1p) return this.$t('for-1p') + ' – ' + this.prices.long_c_1p + ' ₽'
            if ([genre, base_genre].includes(4) && this.prices && this.prices.frame_1m) return this.$t('1min') + ' – ' + this.prices.frame_1m + ' ₽'
            if ([genre, base_genre].includes(5) && this.prices && this.prices.frame_1m) return this.$t('1min') + ' – ' + this.prices.frame_1m + ' ₽'
            if ([genre, base_genre].includes(6) && this.prices && this.prices.ad_30s) return this.$t('upto-30s') + ' – ' + this.prices.ad_30s + ' ₽'

            return ''
        },
    },



    methods: {
        toggleFeatured() {
            this.$axios
                .put('/client/' + this.loggedInUser.id + '/set-featured-narrator', {
                    narrator_id: this.id,
                    status: !this.is_featured,
                })
                .then((res) => {
                    this.is_featured = !this.is_featured
                })

        },

        selectNarrator(id) {
            this.$store.dispatch('setOrderNarrator', id).then(() => {
                this.$root.$emit('close-all-modals')
            })
        },

        setAudioPosition(data) {
            var pos = Math.floor(this.$refs.audio.duration * data.offsetX / data.target.parentElement.offsetWidth)
            this.$refs.audio.currentTime = pos
        },
    },
}
