import * as ov from '@/constants/constants';

export default {
  async nuxtServerInit(vuexContext, { req }) {

    // console.log('serverinit');
    // console.log(vuexContext);
    if (!vuexContext.state.appinfo)
      // console.log(req);
      // console.log(comstate);
      await vuexContext.dispatch('getAppinfo')

  },

  getAppinfo(context, state) {
    // console.log('getAppinfo')
    return this.$axios
      .get('/appinfo')
      .then((res) => {
        context.commit('saveAppinfo', res.data)
      })
      .catch(function (error) {
        if (error.response) {
          // Запрос был сделан, и сервер ответил кодом состояния, который
          // выходит за пределы 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // Запрос был сделан, но ответ не получен
          // `error.request`- это экземпляр XMLHttpRequest в браузере и экземпляр
          // http.ClientRequest в node.js
          // console.log(error.request);
        } else {
          // Произошло что-то при настройке запроса, вызвавшее ошибку
          // console.log('Error', error.message);
        }
        // console.log(error.config);
      });
  },

  setOrderNarrator({ commit, state }, id) {
    // console.log('setOrderNarrator')
    if (!id) return
    return this.$axios
      .get('/narrators/id/' + id, {})
      .then((res) => {
        commit('setOrderNarrator', res.data.narrator)
        commit('toggleOrderProtectionFromCleaning', true)
        this.$router.push(this.localePath('new-order'))
      })
  },

  setOrderPrices({ commit, getters, state }) {
    commit('setOrderPrice', getters.totalPrice())
    commit('setOrderBasePrice', getters.basePrice)
    commit('setOrderCleaningPrice', getters.getCheckedOptionPrice(ov.OPTION_CLEANING))
    commit('setOrderMixingPrice', getters.getCheckedOptionPrice(ov.OPTION_MIXING))
    commit('setOrderAlienationPrice', getters.getCheckedOptionPrice(ov.OPTION_ALIENATION))
    commit('setOrderFederalPrice', getters.getCheckedOptionPrice(ov.OPTION_FEDERAL))
  },

  toggleOrderOption({ commit, getters, state }, id) {
    if (id == ov.OPTION_CLEANING) {
      if (!getters.isOptionPaid(ov.OPTION_CLEANING)) commit('toggleOrderOption', ov.OPTION_CLEANING)
    }
    if (id == ov.OPTION_MIXING) {
      if (!getters.isOptionPaid(ov.OPTION_MIXING)) commit('toggleOrderOption', ov.OPTION_MIXING)
      if (!getters.isOptionPaid(ov.OPTION_CLEANING)) commit('toggleOrderOption', ov.OPTION_CLEANING)
    }
    if (id == ov.OPTION_ALIENATION) {
      if (!getters.isOptionPaid(ov.OPTION_ALIENATION)) commit('toggleOrderOption', ov.OPTION_ALIENATION)
    }
    if (id == ov.OPTION_FEDERAL) {
      if (!getters.isOptionPaid(ov.OPTION_FEDERAL)) commit('toggleOrderOption', ov.OPTION_FEDERAL)
    }
  },

}