
import { mapGetters, mapMutations } from 'vuex'
import * as ov from '@/constants/constants'

export default {
    props: [
        'id',
        'firstname',
        'lastname',
        'image',
        "slug",
    ],

    computed: {
        ...mapGetters(['loggedInUser']),

        narratorImage() {
            return this.image ? this.image : ov.DEFAULT_IMAGE
        },
    },
}
