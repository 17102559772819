
export default {
    props: [
        'id',
        'title',
        'narrators_count',
        'image',
        "slug",
        "emitInsteadOpen",
    ]
}
