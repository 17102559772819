
export default {
    methods: {
        closeModal() {
            this.$root.$emit('close-all-modals')
        },

        successLogin() {
            // console.log('successLogin')
            this.$root.$emit('save_order', 3)
        },

    },
}    
