export default {
  'home': 'Главная',
  'new-order': 'Новый заказ',
  'narratorcomps': 'Подборки',
  'narrators': 'Дикторы',
  'login': 'Войти',
  'blog': 'Блог',
  'become-narrator': 'Стать диктором',
  'help': 'Помощь',
  'how-it-works': 'Как это работает',
  'back': 'Назад',
  'about': 'О сервисе',
  'privacy-policy': 'Политика конфиденциальности',
  'narrator-registration': 'Регистрация диктора',
  'profile': 'Профиль',
  'for-registration': 'Для регистрации на сайте в качестве диктора нужно зайти на сайт по номеру телефона. Этот же номер мы будем использовать для связи в экстренных случаях. Без спама.',
  'personal-data': 'Личные данные',
  'left-to-fill': 'Осталось заполнить',
  'no-reviews-yet': 'Пока нет отзывов',
  'finish-register': 'Завершить регистрацию',
  'ad-example': 'Пример рекламы',
  'audiobook-example': 'Пример аудиокниги',
  'longread-example': 'Пример лонгрида',
  'offframe-example': 'Пример дубляжа',
  'sad-example': 'Грустный ролик',
  'joy-example': 'Веселый ролик',
  'demo-load-text': 'Чем больше примеров работ вы загрузите, тем выше шанс того, что вы попадете в разные подборки и обратятся именно к вам',
  'no-payments': 'Платежи отсутствуют',
  'no-tickets': 'Обращения отсутствуют',
  'no-orders': 'Заказы отсутствуют',
  'no-draft-orders': 'Нет черновиков',
  'no-completed-orders': 'Пока нет выполненных заказов',
  'add-gender': 'Добавить голос',
  'add-age': 'Добавить возраст',
  'add-timbre': 'Добавить тембр голоса',
  'i-read-all': 'Читаю всё',
  'other-female': 'Другая',
  'no-narrator-reviews': 'У этого диктора пока нет отзывов',
  'to-calc-price': 'Просчитать стоимость',
  'price-calculation': 'Просчет стоимости',
  'calc-order-not-logged-text': 'Для того чтобы отправить файлы на просчет диктору, нужно зайти на сайт по номеру телефона. Этот же номер мы будем использовать для связи в экстренных случаях. Никому больше номер не передадим. Без спама.',
  'ideal-quality': 'Идеальное качество материалов',
  'ready-track': 'Готовый ролик',
  'benefits-text1': 'Процесс создания качественного аудио-контента это и творческий подход и профессионализм диктора, сценариста и звукорежиссёра. Мы гордимся нашей командой специалистов!',
  'benefits-text2': 'Не забываем об индивидуальном подходе, и знаем привычки большинства наших заказчиков. Мы помним наизусть е-майл адреса наших постоянных клиентов, при возможности даём скидку и стараемся максимально качественно решить любую задачу.',
  'order-clean-track': 'Заказать ролик с чисткой',
  'order-ready-track': 'Заказать готовый ролик',
  'cancel': 'Отмена',
  'topic-leave-blank': 'Оставьте пустым, если ни одна из указанных тем не затрагивается',
  'urgent-conditions': 'Если заказ создан после 17:00, то он будет выполнен в течение следующего рабочего дня',
  'no-reviews-yet': 'У вас пока нет отзывов',
  'payment': 'Оплата',
  'pay-not-logged-text': 'Для заказа ролика нужно зайти на сайт по номеру телефона. Этот же номер мы будем использовать для связи в экстренных случаях. Никому больше номер не передадим. Без спама.',
  'go-to-payment': 'Перейти к оплате',
  'to-payment': 'К оплате',
  'additional-services': 'Дополнительные услуги',
  'track-order': 'Заказ ролика',
  'from-wallet': 'Из кошелька',
  'you-got-unsaved-order': 'У вас есть несохраненный заказ!',
  'return-to-order': 'Вернуться к заказу',
  'wallet-balance': 'Баланс кошелька',
  'pay-from-wallet': 'Оплатить из кошелька',
  'payment-description': 'Описание платежа',
  'waiting-payment': 'Ожидает оплаты',
  'sent': 'Отправлено',
  'wait-for-approval': 'Ожидайте подтверждения',
  'write-total-cost': 'Напишите итоговую стоимость заказа',
  'total-cost': 'Итоговая стоимость',
  'narrator-work-price': 'Цена за работу диктора',
  'order-accepted': 'Заказ принят',
  'order-approved': 'Заказ подтвержден',
  'you-can-start': 'Можно начинать',
  'processing-by-soundengineer': 'В обработке у звукоинженера',
  'page-not-found': 'Страница не найдена',
  'add-track': 'Добавить трек',
  'withdraw-request-sent': 'Заявка на вывод средств успешно отправлена и будет обработана в ближайшее время',
  'register-finished': 'Поздравляем, Вы закончили регистрацию! Сейчас Ваш профиль находится на модерации, по ее завершению Вы сможете принимать заказы.',
  'notify_status_4': 'Заказ отменен',
  'notify_status_4_for_client': 'Заказ был отменен диктором и сохранен как черновик',
  'notify_status_6': 'Заказ был отменен клиентом',
  'notify_status_101': 'Изменение расчетной цены более невозможно, так как заказ уже принят и оплачен клиентом.',
  'order-not-found': 'Заказ не найден. Возможно, он был отклонен',
  'rate-service': 'Оцените, пожалуйста, качество сервиса',
  'leave-review': 'Оставить отзыв',
  'soundengineer-msg1': 'Файлы в обработке',
  'soundengineer-msg2': 'Диктор отправил результаты работ. Сейчас звукорежиссер занимается их чисткой и сведением. Как только обработка будет завершена, файлы появятся здесь.',
  'topup-and-pay-card': 'Пополнить и оплатить с карты',
  'ok': 'OK',
  'close': 'Закрыть',
  'i-got-it': 'Понятно',
  'service-fee': 'сервисный сбор',
  'total': 'Итого',
  'jpg-png': 'JPEG, PNG, не&nbsp;более 2 МБ',
  'send-to-soundengineer': 'Отправить звукорежиссеру',
  'not-any-topic': 'Без спорных тем',
  'topic-default': 'Не важно',
  'soundengineer': 'Звукорежиссер',
  'order-details': 'Детали заказа',
  'alienation-notice': 'В конце работы отправьте клиенту заполненный и подписанный договор отчуждения прав',
  'price-by-request': 'Договорная цена',
  'search-by-name': 'Поиск по дикторам',
  'safe-notice': 'Если что-то пойдёт не так — вернём деньги в полном объёме',
  'choose-narrator': 'Выберите диктора',
  'add-lazy-narrator': 'Добавить диктора, который<br> не хочет приходить сам',
  'reselect-narrator': 'Сменить диктора',
  'client-notice-refresh-page': 'Не забудьте обновлять страницу, вдруг заказ уже готов',
  'agent': 'Агент',
  'its-your-narrator': 'Это Ваш диктор',
  'all-messages-will-go-to': 'Все сообщения придут на',
  'payment-safe': 'Платеж защищен',
  'with-commission': 'с комиссией',
  'wait': 'Подождите ...',
  'share': 'Поделиться',
  'for-narrators1': 'Для дикторов',
  'for-clients': 'Для клиентов',
  'offer': 'Оферта',

  'confirm-all-done': 'Подтверждаю, всё сделано',
  'narrator-work-not-done': 'Работа диктора пока не выполнена',
  'soundengineer-work-not-done': 'Работа звукорежиссера пока не выполнена',
  'narrator-sent-results': 'Диктор отправил результаты работ',
  'please-confirm-results': 'Пожалуйста, проверьте работу и подтвердите, если задача выполнена правильно.',
  'please-confirm-results-before-se': 'Пожалуйста, проверьте работу, чтобы материалы перешли в руки звукорежиссера для чистки звука, сведения и выполнения услуги',
  'soundengineer-sent-results': 'Звукорежиссер отправил результаты работ',
  'soundengineer-appears-in-chat': 'В чат врывается',
  'order-completed': 'Заказ завершен',
  'main-chat': 'Общий чат',
  'unstable-site': 'Здравствуйте! Мы добавляем на сайт новые возможности. Если Вы вдруг столкнетесь с ошибкой - пожалуйста, напишите в поддержку или на почту help@overvoice.online',

  'narrator_fee_text0': 'Сервисный сбор',
  'narrator_fee_text1': 'При стоимости',
  'narrator_fee_text3': 'при выводе средств остается',

  'narrator_fee_30s': 'до 30 сек. озвучки,',
  'narrator_fee_60s': 'до 1 мин. озвучки,',
  'narrator_fee_90s': 'до 1 мин. 30 сек. озвучки,',
  'narrator_fee_1p': 'до 1 стр. озвучки,',
  'narrator_fee_5p': 'от 5 стр. озвучки,',

  'as-soundengineer': 'Как звукорежиссер',
  'by-phone': 'По телефону',
  'by-email': 'По почте',
  //home
  'all-genres': 'Все жанры',
  'for-narrators': 'Дикторам',
  'all-narratorcomps': 'Все подборки',
  'all-narrators': 'Все дикторы',
  'narrators-selections': 'Подборки дикторов',
  'popular-questions': 'Популярные вопросы',
  'home-narrators-count1': 'Более',
  'home-narrators-count2': 'дикторов, читающих текст для всех жанров',
  'home-languages1': 'Носители',
  'home-languages2': 'языков',
  'articles': 'Статьи',
  'add-narrator': 'Добавьте диктора',
  'add-text': 'Добавьте текст',
  'attach-text': 'Прикрепите текст',
  'attach-file-with-text': 'приложите файл с текстом',
  'file': 'файл',
  'words-count': 'Слов',
  'seconds-count': 'Секунд',
  'link': 'Ссылка',
  'or-link-to-file': 'или ссылку на файл',
  'online-service': 'Онлайн сервис заказа озвучки',
  'delete-link': 'Удалить ссылку',
  'insert-file-link': 'Вставьте ссылку на файл',
  'narrators-24/7': 'Дикторы 24/7',
  'narrators-24/7-text': 'Если вам надо «вчера», мы разорвёмся в клочья, но сделаем проект точно в срок. Бывают случаи, когда ролик должен был быть уже в эфире через 10 минут. Мы с этим справлялись. Звучит невероятно, но факт.',
  'narrating-examples': 'Примеры озвучки',
  'madeby1': 'Сделано в студии',
  'madeby2': 'в',
  'madeby3': 'году',

  //new-order
  'music': 'Музыка',
  'to-genres': 'К жанрам',
  'compositions-plural': 'композиций | композиция | композиции | композиций',
  'add': 'Добавить',
  'remove': 'Убрать',
  'project-name': 'Название проекта',
  'add-music': 'Добавьте музыку',
  'add-comment-2': 'Добавьте комментарий',
  'speed-medium': 'Среднее',
  'speed-slow': 'Медленно',
  'speed-fast': 'Быстро',
  'featured-narrators': 'Избранные',
  'cheap-first': 'Сначала дешевле',
  'expensive-first': 'Сначала подороже',
  'new-first': 'Сначала новые',
  'alphabet-asc': 'По алфавиту А-Я',
  'alphabet-desc': 'По алфавиту Я-А',
  'by-default': 'По умолчанию',
  'genre-other': 'Другое',
  'mood-to-read': 'Настроение, с которым читать',
  'read-speed': 'Скорость чтения',
  'when-result': 'Когда нужен результат',
  'during-day': 'В течение рабочего дня',
  'check-list': 'Чек-лист',
  'narrator-selected': 'Диктор выбран',
  'save-draft': 'Сохранить в черновик',
  'send-for-calc': 'Отправить на просчет',
  'accents-right': 'Знаки ударения в файле проставлены правильно',
  'narrator-not-urgent': 'Этот диктор не сможет сделать задачу в течение часа',
  'music-added': 'Музыка добавлена',
  'change-time': 'Поменять время',
  'change-narrator': 'Поменять диктора',
  'to-time': 'Срок',
  'add-services': 'Добавить доп. услуги',
  'option-cleaning': 'Чистка ролика',
  'option-mixing': 'Сведение',
  'duration': 'Длительность',
  'order-done': 'Заказ выполнен',
  'save-draft-text': 'Для того чтобы сохранить проект, нужно зайти на сайт по номеру телефона. Этот же номер мы будем использовать для связи в экстренных случаях. Никому больше номер не передадим. Без спама.',
  'narrator': 'Диктор',
  'cannot-urgent': ' не может взяться за работу в указанные сроки.',
  'no-current-orders': 'Заказов в работе сейчас нет',
  'clip-length': 'Длина ролика',
  'delete-file': 'Удалить файл',
  'comment': 'Комментарий',
  'change-topic': 'Поменять тему',
  'cannot-topic': ' не работает по данной теме',

  //narratorcomps
  'diktorov': 'дикторов | диктор | диктора | дикторов',
  'copy-link': 'Скопировать ссылку',
  'copied': 'Скопировано!',
  'download': 'Скачать',

  //narrators
  'show-more': 'Показать еще',
  'filters': 'Фильтры',
  'age': 'Возраст',
  'language': 'Язык',
  'genre': 'Жанр',
  'gender': 'Пол',
  'all-genders': 'Любой пол',
  'all-languages': 'Любой язык',
  'all-ages': 'Любой возраст',
  'native': 'Носитель языка',
  'select': 'Выбрать',
  'main': 'Основной',
  'main2': 'Основная',
  'reset': 'Сбросить',
  'reset-filters': 'Сбросить фильтры',
  'about-narrator': 'О дикторе',
  'prices': 'Цены',
  'reviews': 'Отзывы',
  'demo': 'Демо',
  'select-narrator': 'Выбрать диктора',
  'voice-chars': 'Характеристики голоса',
  'voice': 'Голос',
  'voice-timbre': 'Тембр голоса',
  'voice-age': 'Возраст голоса',
  'about-myself': 'О себе',
  'about-me': 'Обо мне',
  'workhours': 'Режим работы',
  'from': 'От',
  'till': 'До',
  'ready-for-immediate-orders': 'Берусь за срочные заказы',
  'specialization': 'Специализация',
  'main-spec': 'Основная',
  'native0': 'Носитель',
  'languages': 'Языки',
  'narrator-not-read': 'Диктор не читает',
  'equipment': 'Оборудование',
  'main-demo': 'Основное демо',
  'work-examples': 'Примеры работ',
  'mood-examples': 'Примеры настроений',
  'all-reviews': 'Все отзывы',
  'to-order': 'Заказать',
  'topic': 'Спорная тема',
  'all-topics': 'Читает всё',

  //blog
  'all-posts': 'Все посты',
  'read-more': 'Читать дальше',
  'load-more': 'Загрузить еще',
  'other-articles': 'Другие статьи',
  'other-guides': 'Другие инструкции',

  //become
  'registration': 'Регистрация',



  //profile
  'logout': 'Выйти',
  'support': 'Поддержка',
  'my-orders': 'Мои заказы',
  'wallet': 'Кошелек',
  'my-profile': 'Мой профиль',
  'you-on-moderate': 'Ваш аккаунт находится на рассмотрении. Как только это сообщение исчезнет — вы сможете принимать заказы.',
  'you-get-call-to-email': 'Мы отправили вам код на эл. адрес',
  'if-no-email-check-spam': 'Если письма с кодом нет, пожалуйста, проверьте также папку со спамом',
  //--settings
  'edit-profile': 'Редактирование профиля',
  'edit': 'Редактировать',
  'save': 'Сохранить',
  'firstname': 'Имя',
  'lastname': 'Фамилия',
  'add-photo': 'Добавить фото',
  'change-photo': 'Изменить фото',
  'your-email': 'Эл. почта',
  'enter-firstname': 'Введите имя',
  'enter-lastname': 'Введите фамилию',
  'enter-email': 'mail@example.com',
  'enter-city': 'Введите город',
  'what-city': 'Из какого города',
  'birthdate': 'Дата рождения',
  'reply': 'Ответить',
  'data-changed-successfully': 'Данные успешно изменены',
  'data-saved-successfully': 'Данные успешно сохранены',
  'add-equipment': 'Добавить оборудование',
  'tell-about-yourself': 'Расскажите кратко о себе',
  'links-if-present': 'Ссылки, если есть',
  'day1': 'ПН',
  'day2': 'ВТ',
  'day3': 'СР',
  'day4': 'ЧТ',
  'day5': 'ПТ',
  'day6': 'СБ',
  'day7': 'ВС',
  'timezone': 'Часовой пояс',
  'add-language': 'Добавить язык',
  'add-topic': 'Добавить тему',
  'in-30min': 'В течение 30 минут от момента принятия заказа',
  'upload-demo': 'Загрузить ролик',
  'work-cost-edit': 'Редактирование стоимости работы',
  'demo-edit': 'Редактирование демороликов',
  'timbre': 'Тембр',
  'speed': 'Скорость',
  'narration-speed': 'Скорость произношения',
  'example': 'Пример',
  'mood-examples': 'Примеры настроений',
  'your-reply-sent': 'Ваш ответ отправлен',
  'insert-link': 'Вставьте ссылку',
  'ad': 'Реклама',
  'ad-desc': 'Рекламный ролик, игровой ролик, информационный',
  'long-desc': 'Аудиогид, презентация, инструкция, IVR&nbsp;автоответчик',
  'upto-30s': 'До 30 сек.',
  'upto-1m': 'До 1 мин.',
  'upto-1m30s': 'До 1 мин. 30 сек.',
  'commercial': 'Коммерческий',
  'commercial-desc': 'Аудиогид, реклама бренда, презентация',
  'upto-1p': 'До 1 стр. А4 14 шрифтом',
  'from-5p': 'От 5 страниц',
  'for-1p': 'за 1 страницу',
  'non-commercial': 'Некоммерческий',
  'non-commercial-desc': 'Например, аудиогид',
  'longread': 'Лонгрид',
  'off-frame': 'Закадровая озвучка',
  'off-frame-desc': 'Фильм, сериал, мультфильм, док. фильм, YouTube',
  '1min': '1 мин.',
  'not-less': 'Не менее',
  'off-frame-desc2': 'Ставка считается по длине всего фильма, независимо от количества текста.',
  'audiobook': 'Аудиокнига',
  'upto-1h': 'До 1 часа',
  'coeffs': 'Увеличивающие коэффициенты',
  'coeff-parody': 'Пародия',
  'option-alienation': 'Отчуждение прав',
  'coeff-urgent': 'Заказ в течение часа',
  'coeff-federal': 'Федеральный ролик',
  'enter-cost': 'Введите стоимость',
  'item-for-1min': 'Ставка за 1 минуту',
  'video-not-less': 'Видеоролик длиной не менее',
  'increasing-factors': 'Увеличивающие коэффициенты',
  'by-topics': 'По темам',
  'enter-percent': 'Введите процент',
  'enter-minutes': 'Введите кол-во минут',
  'off-frame-calc': 'При стоимости <strong>{0} ₽</strong> за 1 мин. озвучка видеоролика длиной <strong>{1} мин.</strong> будет оценена в <strong>{2} ₽</strong> независимо от количества текста.',
  'federal-calc': 'Если обычная ставка {0} ₽ до 1 мин., то увеличение <strong>+{1}%</strong> будет равно <strong>{2} ₽</strong> до 1 мин.',
  'equipment-name': 'Название устройства',
  'add-genre': 'Добавить жанр',
  'remove-pause': 'Убрать паузу',
  'take-pause': 'Взять паузу в работе',
  'pause-1h': '1 час',
  'pause-3h': '3 часа',
  'pause-1d': '1 день',
  'pause-undefined': 'Неопределенный срок',
  'narrator_choose': 'На усмотрение диктора',
  'chosen': 'Выбрано',
  'audiobook-desc': 'Минимальная стоимость заказа — стоимость 1 часа.',
  'audiobook-title': '<strong>Стоимость 1 часа</strong> (около 20 страниц)',
  '1h': '1 час',
  'minimum': 'минимум',
  'buhgalteria': 'Бухгалтерия',
  'add-options': 'Доп. услуги',


  //---orders
  'orders-inwork': 'Заказы в работе',
  'orders-completed': 'Завершенные',
  'orders-all': 'Все заказы',
  'orders-drafts': 'Черновики',
  'completed': 'Завершен',
  'draft': 'Черновик',
  'inwork': 'В работе',
  'urgent': 'В течение часа',
  'cancel-order': 'Отменить заказ',
  'delete-order': 'Удалить заказ',
  'delete-draft': 'Удалить черновик',
  'today': 'Сегодня',
  'yesterday': 'Вчера',
  'accept-order': 'Принять заказ',
  'reject-order': 'Отказаться',
  'price-correction-needed': 'Требуется уточнение цены',
  'result': 'Результат',
  'expand-all-text': 'Раскрыть весь текст',
  'add-comment': 'Добавить комментарий',
  'attach': 'Прикрепить',
  'send': 'Отправить',
  'send_to_soundengineer': 'Отправить звукорежиссеру',
  'pay': 'Оплатить',
  'text': 'Текст',
  'wait-calc': 'Ожидает просчета',
  'narrator-sent-result': 'Диктор отправил результат',
  'order-is-completed': 'Заказ завершен',
  'narrator-not-calculated-yet': 'Диктор еще не посчитал стоимость заказа',
  'goal': 'Цель',
  'mood': 'Настроение',
  'final-cost': 'Итоговая стоимость',
  'min': 'мин.',
  'order-wait-approve': 'Ожидает подтверждения',
  'order-wait-verification': 'Ожидает проверки',
  'first-order1': 'Поздравляем с вашим первым заказом!',
  'first-order2': 'На этом экране будут собираться все ваши заказы. Нажмите на один из них чтобы посмотреть его детали.',
  'order-in-work': 'Заказ в работе',
  'waiting-narrator-accept': 'Ожидайте подтверждения от диктора',
  'note-links-not-eternal': 'Чтобы файлы по ссылке однажды не потерялись, их лучше скачать. Ссылки не вечные.',
  'order-file-too-big': `<strong>Файл слишком большой</strong>. Приложите файл весом не более 100 МБ.
  Если файл больше 100 МБ — воспользутесь файлообменником
  (например, <a target="_blank" rel="nofollow" href="https://disk.yandex.ru">Яндекс Диском</a>)
  и приложите ссылку на файл.`,
  'delete': 'Удалить',
  'narrator-result-message1': 'Диктор отправил результаты работ',
  'narrator-result-message2': 'Пожалуйста, проверьте работу и подтвердите, если задача выполнена правильно.',
  'narrator-result-message3': 'Работа пока не выполнена',
  'narrator-result-message4': 'Подтверждаю, все сделано',
  'comment-added': 'Комментарий добавлен',
  'se-result-message1': 'Звукорежиссер обработал файлы',

  //---wallet
  'balance': 'Баланс',
  'top-up-wallet': 'Пополнить кошелек',
  'card-online': 'Картой онлайн',
  'payment-by-invoice': 'Оплата по счету',
  'withdraw-funds': 'Вывод средств',
  'payments-history': 'История платежей',
  'date': 'Дата',
  'method': 'Способ',
  'sum': 'Сумма',
  'status': 'Статус',
  'document': 'Документ',
  'top-up-wallet': 'Пополнить кошелек',
  'card-online': 'Картой онлайн',
  'payment-by-invoice': 'Оплата по счету',
  'withdraw-funds': 'Вывод средств',
  'by-card-number': 'По номеру карты',
  'by-invoice-number': 'По номеру счета',
  'to-cryptowallet': 'На криптокошелек',
  'withdraw-sum': 'Сумма вывода',
  'top-up-sum': 'Сумма пополнения',
  'act': 'Акт',
  'receipt': 'Квитанция',
  'invoice-for-payment': 'Счет на оплату',
  'top-up': 'Пополнить',
  'new-legal-entity': 'Новое юридическое лицо',
  'generate-and-download-invoice': 'Сформировать и скачать счет',
  'please-fill-requisites': 'Ваш кошелек будет пополнен на указанную сумму как только деньги поступят к нам на счет. Для ускорения процесса вы можете отправить платежное поручение на адрес ',
  'please-fill-requisites2': 'Вы можете указать для пополнения кошелька любую сумму, чтобы в будущем заказ дикторов был быстрее и проще.',
  'enter-sum': 'Введите сумму',
  'bank-corr-account': 'Корреспондентский счет банка',
  'full-company-name': 'Полное название компании',
  'inn': 'ИНН',
  'legal-address': 'Юридический адрес',
  'send-withdrawal-request': 'Отправить заявку на вывод',
  'card-number': 'Номер карты',
  'enter-inn': 'Введите ИНН',
  'enter-address': 'Введите адрес',
  'enter-bik': 'Введите БИК',
  'enter-company-name': 'Введите название компании',
  'enter-bank-name': 'Введите наименование банка',
  'enter-corr-account': 'Введите номер корреспондентского счета',
  'enter-or-paste-number': 'Введите или вставьте номер',
  'enter-number': 'Введите номер',
  'bank-name': 'Наименование банка',
  'current-account-number': 'Номер расчетного счета',
  'cryptowallet-number': 'Номер криптокошелька',
  'bik': 'БИК',
  'invoice-generated': 'Счет сформирован, сейчас он будет предложен к скачиванию',
  'payment-success': 'Оплата прошла успешно',
  'payment-rejected': 'Оплата была отклонена',
  'payment-waiting-for-payment': 'Платежная операция в ожидании оплаты',
  'error-happened': 'Произошла ошибка. <br>Попробуйте еще раз, в случае неудачи обратитесь в поддержку',
  'as-client': 'Как клиент',
  'as-narrator': 'Как диктор',

  //support
  'tickets': 'Обращения',
  'write-to-support': 'Написать в поддержку',
  'guides': 'Инструкции',
  'no-answer1': 'Не нашли ответа на свой вопрос?',
  'no-answer2': 'Напишите нам.',
  'help-mail': 'help@overvoice.online',
  'created_at': 'Создано',
  'updated_at': 'Обновлено',
  'attach-file': 'прикрепить файл',
  'ticket-for-support': 'Обращение в поддержку',
  'enter-ticket-text': 'Введите текст обращения',
  'ticket': 'Обращение',
  'new-ticket-created': 'Ваше обращение принято',
  'your-message-sent': 'Ваше сообщение отправлено',
  'enter-answer': 'Введите ответ',

  //modals
  'excellent': 'Понятно',

  //--login
  'login-to-site': 'Вход на сайт',
  'phone-number': 'Номер телефона',
  'get-code': 'Получить код',
  'agree-text1': 'Продолжая, вы соглашаетесь со',
  'agree-link-text': 'сбором и обработкой персональных данных и пользовательским соглашением',
  'you-get-call-to': 'Вам придет СМС-сообщение на номер',
  'change': 'Изменить',
  'last-4-digits': 'Последние 4 цифры звонящего номера являются вашим кодом',
  'call-back-after': 'Получить код еще раз через',
  'call-back-again': 'Получить код еще раз',
  'sec': 'сек.',

  'without-music': 'Без музыки',
  'to-add-music': 'Добавить музыку',

  'order-returned-to-work': 'Заказ возвращен в работу',
  'self-add-options': 'Самостоятельное выполнение дополнительных услуг',
  'self-cleaning-title': 'Чистка',
  'self-cleaning-desc': 'Оплата услуги чистки будет происходить по тарифам сервиса',

  'to-link': 'подключить',
  'to-unlink': 'отключить',
  'notifications-in-telegram': 'Уведомления в Telegram',
  'token': 'Token',
  'withdraw_disabled': 'Вывод средств пока невозможен, т.к. уже есть необработанная заявка на вывод. Пожалуйста, дождитесь ее обработки.'
}

