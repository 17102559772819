
import { mapGetters } from 'vuex'
import * as ov from '@/constants/constants'

export default {
    data() {
        return {
            modal_state: false,
            modal_name: 'order-options',
            shown_option: 1,
        }
    },

    computed: {
        ...mapGetters(['loggedInUser', 'storeOrder', 'appInfo']),
    },

    methods: {
        closeModal() {
            this.$root.$emit('close-all-modals')
        },
        showOption(id) {
            this.shown_option = id
        },
        getTitle(item) {
            const genre = Object.values(this.appInfo.genres).find(el => el.id === this.storeOrder.genre.id)
            if ((item.id === ov.OPTION_MIXING) && genre.mixing_title) return genre.mixing_title

            return item.title
        }
    },

    mounted() {
        this.$root.$on('open-modal', (data) => {
            if (data.modal == this.modal_name) {
                this.modal_state = true
                this.shown_option = data.id
            }
        })

        this.$root.$on('close-all-modals', () => {
            this.modal_state = false
        })
    },

    beforeDestroy() {
        this.$root.$off('open-modal')
        this.$root.$off('close-all-modals')
    },

    computed: {
        ...mapGetters(['loggedInUser', 'appInfo', 'storeOrder']),
    },


}    
