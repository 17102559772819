
import { mapGetters } from 'vuex'
import * as ov from '@/constants/constants'

export default {
    props: [
        'title',
        'id',
        'text',
        'demos',
    ],

    methods: {
        selectOption() {
            if (!this.$store.getters.isOptionCanBeAdded(this.id)) return
            // this.$store.commit('toggleOrderOption', this.id)
            this.$store.dispatch('toggleOrderOption', this.id)
        },
    },

    computed: {
        ...mapGetters(['loggedInUser', 'storeOrder', 'appInfo']),

        getTitle() {
            const genre = Object.values(this.appInfo.genres).find(el => el.id === this.storeOrder.genre.id)
            if ((this.id === ov.OPTION_MIXING) && genre.mixing_title) return genre.mixing_title

            return this.title
        }
    },
}    
