
import { mapGetters, mapMutations } from 'vuex'
import * as ov from '@/constants/constants'

export default {

    props: [
        'checklist',
    ],

    data() {
        return {
            narrator: null,
            ready_to_view: false,
        }
    },

    methods: {
        saveNarrator() {
            this.$axios
                .put('/narrator/prices',
                    {
                        prices: this.narrator.prices,
                    }
                )
                .then((res) => {
                    this.$emit('updated')
                })
        },

        addCoeff() {
            // console.log(this.narrator.prices)
            if (!this.narrator.prices.coeff) this.narrator.prices.coeff = []
            // const narrator_topics = this.narrator.prices.coeff.map(item => +item['topic'])
            // console.log(narrator_topics)
            // var next = _.find(this.appInfo.topics, function (item) { return !narrator_topics.includes(item.id) })

            var narrator_topics = this.narrator.prices.coeff.map(item => +item.topic)
            var next = Object.values(this.appInfo.topics).find(item => !narrator_topics.includes(item.id))

            if (!next) return false

            this.narrator.prices.coeff.push({
                topic: next.id,
            })
        },

        isGenresInclude(id) {
            return this.narrator.genres.findIndex(item => item.id == id) + 1
        },

        narrator_fee_message(value, timetext = null) {
            var text = this.$t('narrator_fee_text0') + ' – ' + this.appInfo.narrator_withdraw_commission + ' %'
            if (value) {
                var price_with_fee = value - Math.ceil(value * this.appInfo.narrator_withdraw_commission / 100)
                return text + '. ' + this.$t('narrator_fee_text1') + ' <strong>' + value + ' ₽</strong> ' + (timetext ? timetext + ' ' : '') + this.$t('narrator_fee_text3') + ' <strong>' + price_with_fee + ' ₽</strong>.'
            }
            return text
        },

    },

    mounted() {
        this.$axios
            .get('/narrator/prices')
            .then((res) => {
                // console.log(res)
                this.narrator = res.data.narrator

                // if (this.checklist) {
                //     this.narrator.prices.ad_on = this.isGenresInclude(1) || this.isGenresInclude(6) ? true : false
                //     this.narrator.prices.long_on = this.isGenresInclude(3) ? true : false
                //     this.narrator.prices.frame_on = this.isGenresInclude(4) || this.isGenresInclude(5) ? true : false
                //     this.narrator.prices.book_on = this.isGenresInclude(2) ? true : false
                // }

                this.$nextTick(function () {
                    this.ready_to_view = true
                })
            })
    },

    computed: {
        ...mapGetters(['loggedInUser', 'appInfo']),
        ov() {
            return ov
        },
        forbidden_topics_array() {
            if (!this.narrator.topics) return []
            return this.narrator.topics.map(item => item.id ? item.id : +item.id)
        },

        off_frame_calc1() {
            return this.narrator.prices.frame_1m || 50
        },
        off_frame_calc2() {
            return this.narrator.prices.frame_min || 15
        },
        off_frame_calc3() {
            return this.off_frame_calc1 * this.off_frame_calc2
        },

        federal_calc1() {
            return 1000
        },
        federal_calc2() {
            return this.narrator.prices.federal || 100
        },
        federal_calc3() {
            return this.federal_calc1 + Math.ceil(this.federal_calc1 * this.federal_calc2 / 100)
        },

        filledPrices() {
            return (this.narrator.prices.ad_on || this.narrator.prices.long_on || this.narrator.prices.frame_on || this.narrator.prices.book_on)
                && (!this.narrator.prices.ad_on || this.narrator.prices.ad_30s && this.narrator.prices.ad_60s && this.narrator.prices.ad_90s)
                && (!this.narrator.prices.long_on || this.narrator.prices.long_c_1p && this.narrator.prices.long_c_5p)
                && (!this.narrator.prices.frame_on || this.narrator.prices.frame_1m && this.narrator.prices.frame_min)
                && (!this.narrator.prices.book_on || this.narrator.prices.book_1h)
        },

        filledCoeffs() {
            return (String(this.narrator.prices.federal) !== '') && (String(this.narrator.prices.federal) !== 'null')
                && (String(this.narrator.prices.urgent) !== '') && (String(this.narrator.prices.urgent) !== 'null')
                && (String(this.narrator.prices.alienation) !== '') && (String(this.narrator.prices.alienation) !== 'null')
            // && (String(this.narrator.prices.parody) !== '') && (String(this.narrator.prices.parody) !== 'null')
        },

        filledAll() {
            return this.filledCoeffs
            // return this.filledPrices && this.filledCoeffs
        },


    }
}    
