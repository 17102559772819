
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            modal_state: false,
            modal_name: 'reviews',
            reviews: null,
        }
    },

    methods: {
        closeModal() {
            this.$root.$emit('close-all-modals')
        },
    },

    mounted() {
        this.$root.$on('open-modal', (data) => {
            if (data.modal == this.modal_name) {
                this.$axios
                    .get('/narrators/id/' + data.id + '/reviews')
                    .then((res) => {
                        this.reviews = res.data.reviews
                    })
                this.modal_state = true
            }
        })

        this.$root.$on('close-all-modals', () => {
            this.modal_state = false
        })
    },

    beforeDestroy() {
        this.$root.$off('open-modal')
        this.$root.$off('close-all-modals')        
    },

    computed: {
        ...mapGetters(['loggedInUser'])
    }
}    
