
import { mapGetters } from 'vuex'

export default {
    props: [
        'id',
        'track',
        'genres_id',
        'languages_id',
        'ages_id',
        'speeds_id',
        'moods_id',
        'timbres_id',
        'main',
        'opened',
    ],

    data() {
        return {
            genre_input: 0,
            mood_input: 0,
            language_input: 0,
            age_input: 0,
            timbre_input: 0,
            speed_input: 0,
            is_dirty: false,
            edit_opened: false,
        }
    },

    methods: {
        saveDemo() {
            var options = {
                id: this.id,
                genres_id: this.genre_input,
                moods_id: this.mood_input,
                languages_id: this.language_input,
                ages_id: this.age_input,
                timbres_id: this.timbre_input,
                speeds_id: this.speed_input,
            }
            this.$emit('save', options)
            this.is_dirty = false
            this.edit_opened = false
        },
        deleteDemo() {
            this.$emit('delete')
        },
        editDemo() {
            this.edit_opened = !this.edit_opened
        },
    },

    mounted() {
        this.edit_opened = this.opened || !(this.track && this.track.file_name)
        this.genre_input = this.genres_id ?? 0
        this.mood_input = this.moods_id ?? 0
        this.language_input = this.languages_id ?? 0
        this.age_input = this.ages_id ?? 0
        this.timbre_input = this.timbres_id ?? 0
        this.speed_input = this.speeds_id ?? 0
        this.$nextTick(function () {
            this.is_dirty = false
        })
    },

    computed: {
        ...mapGetters(['loggedInUser', 'appInfo'])
    },

    watch: {
        genre_input: function (val, oldVal) {
            this.is_dirty = true
        },
        mood_input: function (val, oldVal) {
            this.is_dirty = true
        },
        language_input: function (val, oldVal) {
            this.is_dirty = true
        },
        age_input: function (val, oldVal) {
            this.is_dirty = true
        },
        timbre_input: function (val, oldVal) {
            this.is_dirty = true
        },
        speed_input: function (val, oldVal) {
            this.is_dirty = true
        },
    }

}    
