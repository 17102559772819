
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters(['appInfo']),

        title() {
            var title = ''

            if (this.main) return this.$t('main-demo')
            if (this.genres_id && this.genreObject) title += (title ? ' • ' : '') + this.$t('genre') + ': ' + this.genreObject.title
            if (this.moods_id) title += (title ? ' • ' : '') + this.$t('mood') + ': ' + this.appInfo.moods[this.moods_id].title
            if (this.timbres_id) title += (title ? ' • ' : '') + this.$t('timbre') + ': ' + this.appInfo.timbres[this.timbres_id].title
            if (this.speeds_id) title += (title ? ' • ' : '') + this.$t('speed') + ': ' + this.appInfo.speeds[this.speeds_id].title
            if (this.ages_id) title += (title ? ' • ' : '') + this.$t('age') + ': ' + this.appInfo.ages[this.ages_id].title
            if (this.languages_id) title += (title ? ' • ' : '') + this.$t('language') + ': ' + this.appInfo.languages[this.languages_id].title
            return title
        },

        genreObject() {
            return Object.values(this.appInfo.genres).find(
                (el) => el.id === this.genres_id
            )
        },
    },

    props: [
        'id',
        'file',
        'genres_id',
        'moods_id',
        'speeds_id',
        'timbres_id',
        'ages_id',
        'languages_id',
        'main',
    ],

    data() {
        return {
            time: '',
        }
    },

    methods: {
        setAudioPosition(data) {
            var pos = Math.floor(this.$refs.audio.duration * data.offsetX / data.target.parentElement.offsetWidth)
            this.$refs.audio.currentTime = pos
        },

        onTouchend() {
            if (window.screen.width < 1220) this.playbackAdd()
        },

        onClick() {
            if (window.screen.width >= 1220) this.playbackAdd()
        },

        playbackAdd() {
            let playButton = this.$refs.playButton
            let targetTrack = playButton.getAttribute('data-target')
            let track = document.getElementById(targetTrack)
            let progressBar = playButton.parentElement.parentElement.querySelector('.slide')
            let progressRange = playButton.parentElement.parentElement.querySelector('.slide .range')
            let progressTiming = playButton.parentElement.parentElement.querySelector('.timing')
            let progressTimingCurrent = playButton.parentElement.parentElement.querySelector('.timing .current')
            let progressTimingTotal = playButton.parentElement.parentElement.querySelector('.timing .max')

            if (!playButton.classList.contains('active')) {
                //stop other tracks
                document.querySelectorAll('.tracks-wrapper .button-playback.active').forEach(function (el) {
                    document.getElementById(el.getAttribute('data-target')).pause()
                    el.classList.remove('active')
                })
                track.play();
                playButton.classList.add('active')
                progressBar.classList.add('active')
                progressTiming.classList.add('active')
            } else {
                track.pause();
                playButton.classList.remove('active')
            }

            // Progress bar and timing
            track.addEventListener('timeupdate', function () {
                var currentTime = track.currentTime
                var duration = track.duration
                progressRange.style.width = (currentTime + .25) / duration * 100 + '%'
                var mins = Math.floor(track.currentTime / 60)
                var secs = Math.floor(track.currentTime % 60)
                secs = String(secs).padStart(2, '0')
                var totalMins = Math.floor(track.duration / 60)
                var totalSecs = Math.floor(track.duration % 60)
                totalSecs = String(totalSecs).padStart(2, '0')
                progressTimingTotal.innerHTML = totalMins + ':' + totalSecs
                progressTimingCurrent.innerHTML = mins + ':' + secs
            });

            // Playback ends
            track.addEventListener('ended', (event) => {
                playButton.classList.remove('active')
                playButton.parentElement.parentElement.querySelector('.slide .range').style.width = "0%"
                progressBar.classList.remove('active')
                progressTiming.classList.remove('active')
            });
        },
    },

    mounted() {
        if (!this.file) return
        const audio = this.$refs.audio
        audio.onloadeddata = () => {
            const duration = Math.floor(audio.duration)
            const minutes = Math.floor(duration / 60)
            const seconds = duration % 60
            this.time = minutes + ':' + String(seconds).padStart(2, '0')
        }
    },

}
