
import { mapGetters } from 'vuex'
import * as ov from '@/constants/constants';

export default {
    data() {
        return {
            modal_state: false,
            modal_name: 'pay-not-logged',
            step: 1,
            shown_option: 1,
            details_opened: false,
            payment_method: ov.WALLET_PAYMENTS_ENABLED ? ov.PAYMENT_WALLET : ov.PAYMENT_CARD,
            was_not_logged: false,
            balance: 0,
            PAYMENT_WALLET: ov.PAYMENT_WALLET,
            PAYMENT_CARD: ov.PAYMENT_CARD,
            PAYMENT_INVOICE: ov.PAYMENT_INVOICE,

            current_company: null,
            companies: null,
            newcompany: {
                title: null,
                inn: null,
                legal_address: null,
                current_account_number: null,
                bank_name: null,
                bik: null,
                bank_corr_account: null,
            },

            loading: false,
        }
    },

    computed: {
        ...mapGetters(['loggedInUser', 'appInfo', 'storeOrder', 'isAuthenticated']),
        ov() {
            return ov
        },
        newCompanyFilled() {
            return this.newcompany.title
                && this.newcompany.inn
                && this.newcompany.legal_address
                && this.newcompany.current_account_number
                && this.newcompany.bank_name
                && this.newcompany.bank_corr_account
        },

        isNewCompany() {
            return this.current_company == 'new'
        },

        getFee() {
            return this.appInfo.client_topup_commission
        },

        feePrice() {
            return Math.ceil(parseInt(this.$store.getters.totalPrice()) * this.getFee / 100)
        },

        sumWithFee() {
            return parseInt(this.$store.getters.totalPrice()) + this.feePrice
        },
    },

    methods: {
        closeModal() {
            this.$root.$emit('close-all-modals')
        },

        showOptionDetails(id) {
            this.shown_option = id
            this.details_opened = true
        },

        successLogin() {
            this.step = 2
            this.loadClientBalanceAndCompanies()
        },



        loadClientBalanceAndCompanies() {
            this.$axios
                .get(`/${this.loggedInUser.role}/payments`)
                .then((res) => {
                    this.balance = res.data.balance
                })
            this.loadCompanies()
            return true
        },

        loadCompanies() {
            this.$axios
                .get('/client/companies')
                .then((res) => {
                    this.companies = res.data.companies
                    if (this.companies.length) this.current_company = res.data.companies[0].id; else this.current_company = 'new'
                })
        },

        deleteCompany(company_id) {
            this.$axios
                .delete('/client/companies/' + company_id,
                    {}
                )
                .then((res) => {
                    this.loadCompanies()
                })
        },

        startPayment(method = null, company = null) {
            this.loading = true
            this.$root.$emit('save_order', this.to_status, method ? method : this.payment_method, company)
            // this.loading = false
            // this.closeModal()
        },

        startInvoice() {
            // console.log('startInvoice')

            var company_id = this.current_company

            if (this.current_company == 'new') {
                this.$axios
                    .post('/client/companies',
                        this.newcompany
                    )
                    .then((res) => {
                        company_id = res.data.id
                        // this.generateInvoice(company_id)
                        this.startPayment(ov.PAYMENT_INVOICE, company_id)
                    })
            }
            else {
                // this.generateInvoice(company_id)
                this.startPayment(ov.PAYMENT_INVOICE, company_id)
            }

            this.closeModal()
            // this.$root.$emit('open-modal', { modal: 'notify', message: this.$t('invoice-generated') })
        },

        // generateInvoice(company_id) {
        //     this.$axios
        //         .post('/client/payments',
        //             {
        //                 sum: this.$store.getters.totalPrice(),
        //                 type: ov.PAYMENT_TOPUP,
        //                 method: ov.PAYMENT_INVOICE,
        //                 company: company_id,
        //             },
        //         )
        //         .then((res) => {
        //             let pdfcontent = Buffer.from(res.data.invoice_content, 'base64')
        //             let a = document.createElement("a");
        //             let file = new Blob([pdfcontent], { type: 'application/pdf' });
        //             a.href = URL.createObjectURL(file);
        //             a.download = `invoice_${res.data.id}.pdf`;
        //             a.click();
        //         })
        // },


    },

    mounted() {
        this.$root.$on('open-modal', (data) => {
            if (data.modal == this.modal_name) {
                // console.log(data)

                if (this.isAuthenticated) {
                    this.loadClientBalanceAndCompanies()
                }

                this.was_not_logged = data.was_not_logged
                this.to_status = data.to_status ? data.to_status : ov.ORDER_WAITING_PAYMENT_FOR_SUGGESTED
                this.step = this.was_not_logged ? 1 : 2
                this.modal_state = true


            }
        })

        this.$root.$on('close-all-modals', () => {
            this.modal_state = false
        })
    },

    beforeDestroy() {
        this.$root.$off('open-modal')
        this.$root.$off('close-all-modals')
    },

}    
