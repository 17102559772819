import { render, staticRenderFns } from "./NarratorSettingsDemo.vue?vue&type=template&id=36188734"
import script from "./NarratorSettingsDemo.vue?vue&type=script&lang=js"
export * from "./NarratorSettingsDemo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NarratorDemoInside: require('/var/www/frontend/components/profile_settings/NarratorDemoInside.vue').default})
