
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            modal_state: false,
            modal_name: 'music',
            musiccomps: null,
            musiccomp: null,
            musiccomp_shown: false,
        }
    },

    methods: {
        closeModal() {
            

            this.$root.$emit('close-all-modals')
        },

        showMusiccomp(id) {
            this.$axios
                    .get('/musiccomps/'+id)
                    .then((res) => {
                        this.musiccomp = res.data.musiccomp

                        this.musiccomp_shown = true
                })            
            
        },
        
        downloadMusiccomp(id) {

        },

        copyLinkMusiccomp(id) {

        },

        addOrderMusic(id) {
            this.$store.commit('addOrderMusic',this.musiccomp.musics[id])
            this.$root.$emit('music_updated')
            this.closeModal()
        },

        removeOrderMusic(id) {
            this.$store.commit('removeOrderMusic',id)
            this.$root.$emit('music_updated')
            this.closeModal()
        },

    },

    mounted() {
        this.$root.$on('open-modal', (data) => {
            if (data.modal == this.modal_name) {
                this.$axios
                    .get('/musiccomps')
                    .then((res) => {
                        this.musiccomps = res.data.musiccomps
                })

                this.modal_state = true
            }
        })

        this.$root.$on('close-all-modals', () => {
            this.musiccomp_shown = false
            this.modal_state = false
        })
    },

    beforeDestroy() {
        this.$root.$off('open-modal')
        this.$root.$off('close-all-modals')        
    },

    computed: {
        ...mapGetters(['loggedInUser','storeOrder']),


    }
}    
