
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            modal_name: 'new-ticket',
            modal_state: false,
            modal_active: true,
            ticket: {
                text: null,
                file: null,
            },
            file_selected: false,
        }
    },

    mounted() {
        this.$root.$on('open-modal', (data) => {
            if (data.modal == this.modal_name) {
                this.modal_state = true
            }
        })

        this.$root.$on('close-all-modals', () => {
            this.modal_state = false
        })
    },

    beforeDestroy() {
        this.$root.$off('open-modal')
        this.$root.$off('close-all-modals')
    },

    methods: {
        closeModal() {
            this.modal_active = true
            this.$root.$emit('close-all-modals')
        },
        deactivateModal() {
            this.modal_active = false
            this.$root.$emit('hide-overlay')
        },        
        startTicket() {
            this.closeModal()
            this.$nextTick(() => {
                this.$router.push(this.localePath('profile-support-tickets'))
                this.$root.$emit('refreshTickets')
            })

        },
    },
}    
