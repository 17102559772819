import { render, staticRenderFns } from "./Modals.vue?vue&type=template&id=0ae4467d"
import script from "./Modals.vue?vue&type=script&lang=js"
export * from "./Modals.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModalHowItWorks: require('/var/www/frontend/components/modal/HowItWorks.vue').default,ModalLogin: require('/var/www/frontend/components/modal/Login.vue').default,ModalRegister: require('/var/www/frontend/components/modal/Register.vue').default,ModalMusic: require('/var/www/frontend/components/modal/Music.vue').default,ModalClientSettings: require('/var/www/frontend/components/modal/ClientSettings.vue').default,ModalNarratorSettings: require('/var/www/frontend/components/modal/NarratorSettings.vue').default,ModalSoundengineerSettings: require('/var/www/frontend/components/modal/SoundengineerSettings.vue').default,ModalNarratorSettingsPrices: require('/var/www/frontend/components/modal/NarratorSettingsPrices.vue').default,ModalNarratorSettingsDemo: require('/var/www/frontend/components/modal/NarratorSettingsDemo.vue').default,ModalClientTopUpWallet: require('/var/www/frontend/components/modal/ClientTopUpWallet.vue').default,ModalNarratorWithdraw: require('/var/www/frontend/components/modal/NarratorWithdraw.vue').default,ModalNewTicket: require('/var/www/frontend/components/modal/NewTicket.vue').default,ModalNarrators: require('/var/www/frontend/components/modal/Narrators.vue').default,ModalPause: require('/var/www/frontend/components/modal/Pause.vue').default,ModalReviews: require('/var/www/frontend/components/modal/Reviews.vue').default,ModalSaveDraft: require('/var/www/frontend/components/modal/SaveDraft.vue').default,ModalOrderOptions: require('/var/www/frontend/components/modal/OrderOptions.vue').default,ModalCalcOrderNotLogged: require('/var/www/frontend/components/modal/CalcOrderNotLogged.vue').default,ModalPayNotLogged: require('/var/www/frontend/components/modal/PayNotLogged.vue').default,ModalNotify: require('/var/www/frontend/components/modal/Notify.vue').default,ModalAgentNarratorChange: require('/var/www/frontend/components/modal/AgentNarratorChange.vue').default})
