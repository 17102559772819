import { render, staticRenderFns } from "./DemoInsert.vue?vue&type=template&id=fc56bbc4"
import script from "./DemoInsert.vue?vue&type=script&lang=js"
export * from "./DemoInsert.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Select: require('/var/www/frontend/components/ui/Select.vue').default})
