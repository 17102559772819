
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            modal_state: false,
            modal_name: 'register',
            step: 1,
        }
    },

    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser']),
    },

    mounted() {
        this.$root.$on('open-modal', (data) => {
            if (data.modal == this.modal_name) {
                this.modal_state = true
                if (data.step) this.step = data.step//для регистрации пропускаем шаг
            }
        })

        this.$root.$on('close-all-modals', (data = null) => {
            if (data && data.force_close) {
                // console.log(data)
                this.modal_state = false
                this.step = 1
            }
        })
    },

    beforeDestroy() {
        this.$root.$off('open-modal')
        this.$root.$off('close-all-modals')
    },

    methods: {
        closeModal(data = null) {
            this.$root.$emit('close-all-modals', data)
            if (this.isAuthenticated) this.$router.push(this.localePath('profile-settings'))
        },

        goStep(step) {
            this.step = step
        },

        goSettings() {
            if (typeof ym !== 'undefined') ym(94196688,'reachGoal','narrator_register');
            // console.log('narrator_register')
            this.closeModal({ 'force_close': true })
            this.$router.push(this.localePath('profile-settings') + '?register=finished')
        },
    },

}    
