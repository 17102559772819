// Импортируем константу SITE_CLOSED
import { SITE_CLOSED } from '~/constants/constants.js';

export default function ({ redirect, route, store }) {
    // Проверка, если сайт закрыт
    if (SITE_CLOSED) {
        // Редирект на главную страницу
        return redirect('/');
    }

    if (typeof store.state.appinfo.referalprograms !== 'undefined') {
        var res = store.state.appinfo.referalprograms.find(item => route.fullPath.includes(item.url_condition));
        if (typeof res !== 'undefined') store.commit('setReferalUrl', res.url_condition);
    }
}