import * as ov from '@/constants/constants';

export default {
    isAuthenticated(state) {
        // if (process.client) {
        //     console.log('client isAuthenticated ' + state.auth.loggedIn)
        // }
        // if (process.server) {
        //     console.log('server isAuthenticated ' + state.auth.loggedIn)
        // }

        // // Проверяем, что state.auth.user определен и не равен null
        // if (state.auth && state.auth.user) {
        //     // Проверяем, что state.auth.user.id определен и не равен нулю
        //     return state.auth.user.id !== undefined && state.auth.user.id !== 0;
        // }
        // // Вернуть false, если одно из условий не выполняется
        // return false;

        return state.auth.loggedIn
    },

    loggedInUser(state) {
        return state.auth.user
    },

    storeOrder(state) {
        return state.order
    },

    appInfo(state) {
        return state.appinfo
    },

    stateTimer(state) {
        return state.state_timer
    },

    pauseTimer(state) {
        return state.pause_timer
    },

    getOrderstatusTitle: (state, getters) => (id = null) => {
        var index = state.appinfo.orderstatuses.findIndex(el => el.id == id)
        if (index + 1) return state.appinfo.orderstatuses[index].title
    },

    hasUserDraftOrders(state) {
        var index = state.auth.user.orders.findIndex(el => el.orderstatuses_id == ov.ORDER_DRAFT)
        return !(index + 1)
    },

    hasUserCompletedOrders(state) {
        var index = state.auth.user.orders.findIndex(el => el.orderstatuses_id == ov.ORDER_COMPLETED)
        return !(index + 1)
    },

    hasUserCurrentOrders(state) {
        if (!state.auth.user.orders) return 0
        var index = state.auth.user.orders.findIndex(el => ov.CURRENT_ORDERS_STATUSES.includes(el.orderstatuses_id))
        return !(index + 1)
    },

    isOrderLastCommentWithFiles(state) {
        try {
            var narrator_last_comment_with_files_index = Object.values(state.order.ordermessages).findLastIndex(
                el => el.files.length && ((el.from == state.order.narrators_id) || (el.from == state.order.soundengineers_id))
            )

            return state.order.ordermessages.length
                && (narrator_last_comment_with_files_index + 1)
        } catch (e) {

        }
    },

    getOrderTimesAndPages(state, getters) {
        const words_in_a4 = 350

        var text_time
        var words = state.order.words_count

        const speed = 2//state.order.speed ? state.order.speed : 2
        if (speed == 1) text_time = state.order.time_slow
        if (speed == 2) text_time = state.order.time_optimal
        if ((speed == 3) || (speed == 4)) text_time = state.order.time_fast
        const seconds = text_time
        const minutes = Math.ceil(seconds / 60)
        const hours = Math.ceil(seconds / 3600)
        const pages = Math.ceil(words / words_in_a4)

        return {
            seconds: seconds,
            minutes: minutes,
            hours: hours,
            pages: pages,
        }
    },

    basePrice(state, getters) {
        const orderGenre = getters.getOrderGenre

        if (state.order.base_price) return state.order.base_price

        if (
            !(state.order.narrator && state.order.narrator.id)
            || !state.order.text
            || !state.order.narrator.prices
            || orderGenre.only_to_calculate
        ) return 0

        var times_and_pages = getters.getOrderTimesAndPages

        var base_price = 0

        const seconds = times_and_pages['seconds']
        const minutes = times_and_pages['minutes']
        const hours = times_and_pages['hours']
        const pages = times_and_pages['pages']

        if ([ov.GENRE_ADVERT, ov.GENRE_PLAY].includes(orderGenre.id) || [ov.GENRE_ADVERT, ov.GENRE_PLAY].includes(orderGenre.pricing_genre_id)) {
            if (seconds <= 90) base_price = state.order.narrator.prices.ad_90s
            if (seconds <= 60) base_price = state.order.narrator.prices.ad_60s
            if (seconds <= 30) base_price = state.order.narrator.prices.ad_30s
        }
        if ([ov.GENRE_AUDIOBOOK].includes(orderGenre.id) || [ov.GENRE_AUDIOBOOK].includes(orderGenre.pricing_genre_id)) {
            base_price = hours * state.order.narrator.prices.book_1h
        }
        if ([ov.GENRE_LONGREAD].includes(orderGenre.id) || [ov.GENRE_LONGREAD].includes(orderGenre.pricing_genre_id)) {
            base_price = pages < 5 ? pages * state.order.narrator.prices.long_c_1p : pages * state.order.narrator.prices.long_c_5p
        }
        if ([ov.GENRE_OFFFRAME, ov.GENRE_YOUTUBE].includes(orderGenre.id) || [ov.GENRE_OFFFRAME, ov.GENRE_YOUTUBE].includes(orderGenre.pricing_genre_id)) {
            if (!parseInt(state.order.clip_length)) return 0
            base_price = Math.max(state.order.narrator.prices.frame_min, state.order.clip_length) * state.order.narrator.prices.frame_1m
        }

        return base_price
    },

    topicPrice(state, getters) {
        var base_price = getters.basePrice
        var price

        if (!(state.order.narrator && state.order.narrator.id) || !state.order.text || !state.order.narrator.prices || !state.order.narrator.prices.coeff) {
            price = 0
        }
        else {
            var coeff = state.order.narrator.prices.coeff.find(item => item.topic == state.order.topic)
            if (coeff) price = base_price * coeff.value / 100; else price = 0
        }
        return price
    },

    totalPrice: (state, getters) => () => {
        var base_price = getters.basePrice
        var price = base_price + getters.topicPrice

        if (!price) return 0
        if (state.order.urgent && state.order.narrator.prices.urgent) price = price + Math.ceil(base_price * state.order.narrator.prices.urgent / 100)
        price = price
            + getters.getCheckedOptionPrice(ov.OPTION_CLEANING)
            + getters.getCheckedOptionPrice(ov.OPTION_MIXING)
            + getters.getCheckedOptionPrice(ov.OPTION_ALIENATION)
            + getters.getCheckedOptionPrice(ov.OPTION_FEDERAL)

        if (typeof state.order.total_paid !== 'undefined') price = price - state.order.total_paid
        return price
    },

    priceLabel: (state, getters, context) => (id) => {
        var base_price = getters.basePrice

        if (id == ov.OPTION_CLEANING) {
            // if (base_price) {
            var price = getters.getOptionPrice(id)
            return price ? '+' + Intl.NumberFormat(context.i18n.locale).format(price) + ' ₽' : ''
            // }
            // else return '+' + state.appinfo.orderoptions[id].percent + ' %'
        }

        if (id == ov.OPTION_MIXING) {
            // if (base_price) {
            var price = getters.getOptionPrice(id)
            return price ? '+' + Intl.NumberFormat(context.i18n.locale).format(price) + ' ₽' : ''
            // }
            // else return '+' + state.appinfo.orderoptions[id].percent + ' %'
        }

        if (id == ov.OPTION_ALIENATION) {
            return getters.getOptionPrice(id) ? '+' + Intl.NumberFormat(context.i18n.locale).format(getters.getOptionPrice(id)) + ' ₽' : ''
        }

        if (id == ov.OPTION_FEDERAL) {
            if (base_price) {
                return getters.getOptionPrice(id) ? '+' + Intl.NumberFormat(context.i18n.locale).format(getters.getOptionPrice(id)) + ' ₽' : ''
            } else {
                if (state.order.narrator && state.order.narrator.id) return state.order.narrator.prices.federal ? '+' + state.order.narrator.prices.federal + ' %' : ''
                return
            }
        }
    },

    additionalTotalPriceLabel: (state, getters) => {
        var addprice = getters.additionalTotalPrice
        return addprice > 0 ? '+ ' + getters.formatPrice(addprice) + ' ₽' : ''
    },

    additionalTotalPrice: (state, getters) => {
        return getters.totalPrice - state.order.balance
    },

    getOrderGenre: (state, getters) => {
        return Object.values(state.appinfo.genres).find(el => el.id === state.order.genre.id)
    },

    getOptionPrice: (state, getters) => (id) => {
        const orderGenre = getters.getOrderGenre

        var base_price = getters.basePrice
        var price = 0
        var unknown_time_price = 0

        const times = getters.getOrderTimesAndPages
        var seconds = times['seconds']
        var hours = times['hours']
        var pages = times['pages']

        // if (orderGenre.only_to_calculate) {
        //     seconds = 0
        //     hours = 0
        //     pages = 0
        // }

        const cleaning_advert_seconds_portion = state.appinfo.cleaning_advert_seconds_portion
        const cleaning_advert_first_portion = state.appinfo.cleaning_advert_first_portion
        const cleaning_advert_next_portion = state.appinfo.cleaning_advert_next_portion
        const cleaning_audiobook_every_hour = state.appinfo.cleaning_audiobook_every_hour
        const cleaning_longread_first_page = state.appinfo.cleaning_longread_first_page
        const cleaning_longread_second_page = state.appinfo.cleaning_longread_second_page
        const cleaning_longread_decreaser = state.appinfo.cleaning_longread_decreaser
        const mixing_advert_seconds_portion = state.appinfo.mixing_advert_seconds_portion
        const mixing_advert_first_portion = state.appinfo.mixing_advert_first_portion
        const mixing_advert_second_portion = state.appinfo.mixing_advert_second_portion
        const mixing_advert_third_portion = state.appinfo.mixing_advert_third_portion

        if (id == ov.OPTION_CLEANING) {
            if (getters.isOptionPaid(id)) return state.order.cleaning_price

            // unknown_time_price = Math.ceil(base_price * state.appinfo.orderoptions[1].percent / 100)
            unknown_time_price = Math.ceil(base_price * 0.5)

            if (!getters.isOptionChecked(ov.OPTION_MIXING)) { //если включен готовый ролик, то цена чистки = 0
                if ([ov.GENRE_ADVERT, ov.GENRE_PLAY].includes(orderGenre.id) || [ov.GENRE_ADVERT, ov.GENRE_PLAY].includes(orderGenre.pricing_genre_id)
                ) {
                    if (seconds) {
                        var first30_counted = false
                        do {
                            if (!first30_counted) {
                                price += cleaning_advert_first_portion
                                first30_counted = true
                            }
                            else {
                                price += cleaning_advert_next_portion
                            }
                            seconds = seconds - cleaning_advert_seconds_portion
                        } while (seconds > 0);
                    }
                    else {
                        price = unknown_time_price
                    }
                }

                if ([ov.GENRE_AUDIOBOOK].includes(orderGenre.id) || [ov.GENRE_AUDIOBOOK].includes(orderGenre.pricing_genre_id)) {
                    if (hours) {
                        do {
                            price += cleaning_audiobook_every_hour
                            hours = hours - 1
                        } while (hours > 0);
                    }
                    else {
                        price = unknown_time_price
                    }
                }

                if ([ov.GENRE_LONGREAD].includes(orderGenre.id) || [ov.GENRE_LONGREAD].includes(orderGenre.pricing_genre_id)) {
                    if (pages) {
                        var first_page_counted = false
                        var second_page_counted = false
                        var page_price = cleaning_longread_second_page
                        do {
                            if (!first_page_counted) {
                                price += cleaning_longread_first_page
                                first_page_counted = true
                            }
                            else if (!second_page_counted) {
                                price += cleaning_longread_second_page
                                second_page_counted = true
                            }
                            else {
                                if (page_price - cleaning_longread_decreaser > 0) page_price -= cleaning_longread_decreaser
                                if (page_price < 0) page_price = 0
                                price += page_price
                            }
                            pages = pages - 1
                        } while (pages > 0);
                    }
                    else {
                        price = unknown_time_price
                    }
                }

            }
        }
        if (id == ov.OPTION_MIXING) {
            if (getters.isOptionPaid(id)) return state.order.mixing_price

            // unknown_time_price = Math.ceil(base_price * state.appinfo.orderoptions[2].percent / 100)
            unknown_time_price = Math.ceil(base_price * 0.5)
            unknown_time_price = unknown_time_price < 2000 ? 2000 : unknown_time_price

            if ([ov.GENRE_ADVERT, ov.GENRE_PLAY].includes(orderGenre.id) || [ov.GENRE_ADVERT, ov.GENRE_PLAY].includes(orderGenre.pricing_genre_id)) {

                if (seconds) {
                    var first30_counted = false
                    var second30_counted = false
                    do {
                        if (!first30_counted) {
                            price += mixing_advert_first_portion
                            first30_counted = true
                        }
                        else if (!second30_counted) {
                            price += mixing_advert_second_portion
                            second30_counted = true
                        }
                        else {
                            price += mixing_advert_third_portion
                        }
                        seconds = seconds - mixing_advert_seconds_portion
                    } while (seconds > 0);
                }
                else {
                    price = unknown_time_price
                }
            }
        }
        if (id == ov.OPTION_ALIENATION) {
            if (getters.isOptionPaid(id)) return state.order.alienation_price

            if (state.order.narrator && state.order.narrator.id && state.order.narrator.prices && state.order.narrator.prices.alienation) {
                price = state.order.narrator.prices.alienation
            }
        }
        if (id == ov.OPTION_FEDERAL) {
            if (getters.isOptionPaid(id)) return state.order.federal_price

            if (base_price && state.order.narrator && state.order.narrator.id && state.order.narrator.prices && state.order.narrator.prices.federal) {
                price = Math.ceil(base_price * state.order.narrator.prices.federal / 100)
            }
        }

        return price
    },


    getCheckedOptionPrice: (state, getters) => (id) => {
        return getters.isOptionChecked(id) * getters.getOptionPrice(id)
    },

    formatPrice: (state, getters, context) => (price) => {
        return Intl.NumberFormat(context.i18n.locale).format(price)
    },

    narratorPrice: (state, getters) => {
        var base_price = getters.basePrice
        var price = base_price + getters.topicPrice

        if (!price) return 0
        if (state.order.urgent && state.order.narrator.prices.urgent) price = price + Math.ceil(base_price * state.order.narrator.prices.urgent / 100)

        var arr = [ov.OPTION_CLEANING, ov.OPTION_MIXING, ov.OPTION_ALIENATION, ov.OPTION_FEDERAL]
        arr.forEach(el => {
            price = price + (getters.isOptionForNarrator(el) ? getters.getCheckedOptionPrice(el) : 0)
        })

        // console.log(state.order.total_paid,state.order.price)
        // if (typeof state.order.total_paid !== 'undefined') price = price - state.order.total_paid

        return price
    },

    isOptionChecked: (state, getters) => (id) => {
        if (id == ov.OPTION_CLEANING) return state.order.cleaning
        if (id == ov.OPTION_MIXING) return state.order.mixing
        if (id == ov.OPTION_ALIENATION) return state.order.alienation
        if (id == ov.OPTION_FEDERAL) return state.order.federal
        return
    },

    isOptionDisabled: (state, getters) => (id) => {
        const orderGenre = getters.getOrderGenre

        if (id == ov.OPTION_CLEANING) {
            if (state.order.musics && state.order.musics.length
                || getters.isOptionChecked(ov.OPTION_MIXING)
                || [ov.GENRE_AUDIOBOOK].includes(orderGenre.id)
                || [ov.GENRE_AUDIOBOOK].includes(orderGenre.pricing_genre_id)
            ) return true
        }
        if (id == ov.OPTION_MIXING) {
            if (state.order.musics && state.order.musics.length) return true
        }
        return false
    },

    isOptionShowable: (state, getters) => (id) => {
        const orderGenre = getters.getOrderGenre

        if (id == ov.OPTION_CLEANING) {
            return orderGenre.cleaning_available
        }
        if (id == ov.OPTION_MIXING) {
            return orderGenre.mixing_available
        }
        if (id == ov.OPTION_ALIENATION) {
            return orderGenre.alienation_available
        }
        if (id == ov.OPTION_FEDERAL) {
            return orderGenre.federal_available
        }
        return true
    },

    isOptionPriceShowable: (state, getters) => (id) => {
        const orderGenre = getters.getOrderGenre

        if (id == ov.OPTION_CLEANING) {
            if (orderGenre.pricing_genre_id) return false
        }
        if (id == ov.OPTION_MIXING) {
            if (orderGenre.pricing_genre_id) return false
        }

        return true
    },


    isOptionPaid: (state, getters) => (id) => {
        if (id == ov.OPTION_CLEANING) {
            return state.order.cleaning_paid
        }
        if (id == ov.OPTION_MIXING) {
            return state.order.mixing_paid
        }
        if (id == ov.OPTION_ALIENATION) {
            return state.order.alienation_paid
        }
        if (id == ov.OPTION_FEDERAL) {
            return state.order.federal_paid
        }
    },

    isOptionCheckedAndNotPaid: (state, getters) => (id) => {
        return getters.isOptionChecked(id) && !getters.isOptionPaid(id)
    },

    isOptionCanBeAdded: (state, getters) => (id) => {
        return getters.isOptionShowable(id) && !getters.isOptionDisabled(id) && !getters.isOptionPaid(id)
    },

    isAnyNarratorOptionChecked: (state, getters) => {
        return getters.isOptionChecked(ov.OPTION_ALIENATION)
            || getters.isOptionChecked(ov.OPTION_FEDERAL)
            || (getters.isOptionChecked(ov.OPTION_CLEANING) && state.order.cleaning_by_narrator && state.order.cleaning_price)
    },

    isAnyClientOptionChecked: (state, getters) => {
        return getters.isOptionChecked(ov.OPTION_CLEANING)
            || getters.isOptionChecked(ov.OPTION_MIXING)
            || getters.isOptionChecked(ov.OPTION_ALIENATION)
            || getters.isOptionChecked(ov.OPTION_FEDERAL)
    },

    isAnyClientOptionCheckedAndNotPaid: (state, getters) => {
        return getters.isOptionChecked(ov.OPTION_CLEANING) && !getters.isOptionPaid(ov.OPTION_CLEANING)
            || getters.isOptionChecked(ov.OPTION_MIXING) && !getters.isOptionPaid(ov.OPTION_MIXING)
            || getters.isOptionChecked(ov.OPTION_ALIENATION) && !getters.isOptionPaid(ov.OPTION_ALIENATION)
            || getters.isOptionChecked(ov.OPTION_FEDERAL) && !getters.isOptionPaid(ov.OPTION_FEDERAL)
    },

    isAnySoundengineerOptionChecked: (state, getters) => {
        return (getters.isOptionChecked(ov.OPTION_CLEANING) && !state.order.cleaning_by_narrator)
            || getters.isOptionChecked(ov.OPTION_MIXING)
    },

    isOptionForNarrator: (state, getters) => (id) => {
        return [ov.OPTION_ALIENATION, ov.OPTION_FEDERAL].includes(id)
            || ((id == ov.OPTION_CLEANING) && state.order.cleaning_by_narrator)
    },

    isGenreAvailable: (state, getters) => (id) => {
        var times_and_pages = getters.getOrderTimesAndPages
        const seconds = times_and_pages['seconds']

        const genre = Object.values(state.appinfo.genres).find(el => el.id === id)
        if (genre.max_seconds && (seconds > genre.max_seconds)) {
            return false
        }

        if (genre.min_seconds && (seconds < genre.min_seconds)) {
            return false
        }

        if ([ov.GENRE_LONGREAD].includes(id)) {
            if ((seconds <= 90) && !state.order.link && !state.order.file) {
                return false
            }
        }

        return true
    },

    isGenreShowable: (state, getters) => (id) => {
        const genre = Object.values(state.appinfo.genres).find(el => el.id === id)
        if ((genre === undefined) || (genre.active === undefined)) return false
        return genre.active
    },

    isMusicAvailable: (state, getters) => {
        const orderGenre = getters.getOrderGenre
        return orderGenre.music_available ?? false
    },

}