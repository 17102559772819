
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters(['loggedInUser', 'appInfo']),
    },

    methods: {
        closeModal() {
            this.$root.$emit('close-all-modals')
        },
    },
}    
