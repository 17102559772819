const middleware = {}

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['hookEveryUrl'] = require('../middleware/hookEveryUrl.js')
middleware['hookEveryUrl'] = middleware['hookEveryUrl'].default || middleware['hookEveryUrl']

middleware['setAppinfo'] = require('../middleware/setAppinfo.js')
middleware['setAppinfo'] = middleware['setAppinfo'].default || middleware['setAppinfo']

export default middleware
