
import { mapGetters } from 'vuex'
import * as ov from '@/constants/constants'

export default {
    props: [
        'id',
        'title',
        'same_window',
    ],

    computed: {
        ...mapGetters(['loggedInUser', 'appInfo', 'storeOrder']),

        isDisabled() {
            return !this.$store.getters.isOptionCanBeAdded(this.id)
        },

        isOptionChecked() {
            return this.$store.getters.isOptionChecked(this.id)
        },

        getTitle() {
            const genre = Object.values(this.appInfo.genres).find(el => el.id === this.storeOrder.genre.id)
            if ((this.id === ov.OPTION_MIXING) && genre.mixing_title) return genre.mixing_title

            return this.title
        }
    },

    methods: {
        toggleOption(id) {
            this.$store.dispatch('toggleOrderOption', id)
            this.$emit('onToggleOrderOption', id)
        },

        showOptions(id) {
            if (!this.same_window) {
                this.$root.$emit('open-modal', {
                    modal: 'order-options',
                    id: id,
                })
            }
            else {
                this.$emit('show_option_details', id)
            }
        },
    },
}    
