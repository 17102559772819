
import { mapGetters } from 'vuex'
import * as ov from '@/constants/constants';

export default {
    data() {
        return {
            PAYMENT_CARD: ov.PAYMENT_CARD,
            PAYMENT_INVOICE: ov.PAYMENT_INVOICE,
            PAYMENT_CRYPTO: ov.PAYMENT_CRYPTO,
            modal_state: false,
            modal_name: 'narrator-withdraw',
            withdraw_method: ov.PAYMENT_CARD,
            current_company: null,
            companies: null,
            card_number: null,
            crypto_number: null,
            sum: null,
            newcompany: {
                title: null,
                inn: null,
                legal_address: null,
                current_account_number: null,
                bank_name: null,
                bik: null,
                bank_corr_account: null,
            },

            balance: 0,

            loading: false,
        }
    },

    computed: {
        ...mapGetters(['loggedInUser', 'appInfo']),

        getFee() {
            return this.appInfo.narrator_withdraw_commission
        },

        feePrice() {
            return Math.ceil(parseInt(this.sum) * this.getFee / 100)
        },

        sumWithFee() {
            return parseInt(this.sum) - this.feePrice
        },

        canWithdrawToCard() {
            return (parseInt(this.sum) <= parseInt(this.balance)) && this.card_number
        },

        canWithdrawToCrypto() {
            return (parseInt(this.sum) <= parseInt(this.balance)) && this.crypto_number
        },

        canWithdrawToInvoice() {
            return (parseInt(this.sum) <= parseInt(this.balance))
        },
    },

    methods: {
        closeModal() {
            this.$root.$emit('close-all-modals')
        },

        loadCompanies() {
            this.$axios
                .get('/narrator/companies')
                .then((res) => {
                    this.companies = res.data.companies
                    if (this.companies.length) this.current_company = res.data.companies[0].id; else this.current_company = 'new'
                })
        },

        deleteCompany(company_id) {
            // console.log('del')
            this.$axios
                .delete('/narrator/companies/' + company_id,
                    {}
                )
                .then((res) => {
                    this.loadCompanies()
                })
        },

        saveCompany() {
            this.$axios
                .put('/narrator/companies',
                    this.newcompany
                )
                .then((res) => {

                })
        },

        generateWithdrawPayment(company_id = null) {
            this.$axios
                .post(`/${this.loggedInUser.role}/payments`,
                    {
                        sum: this.sum,
                        type: ov.PAYMENT_WITHDRAW_NARRATOR,
                        method: this.withdraw_method,
                        card_number: this.withdraw_method == ov.PAYMENT_CARD ? this.card_number : '',
                        crypto_number: this.withdraw_method == ov.PAYMENT_CRYPTO ? this.crypto_number : '',
                        company: this.withdraw_method == ov.PAYMENT_INVOICE ? company_id : '',
                    }
                )
                .then((res) => {
                    console.log(res)
                    // console.log(res.data)
                    this.closeModal()
                    this.$root.$emit('open-modal', { modal: 'notify', message: this.$t('withdraw-request-sent') })
                    this.$root.$emit('reload-payments')
                })
                .catch((err) => {
                    // console.log(err.response)
                    if (err.response.status == 422) {
                        if (err.response.data.error == 'ANOTHER_WITHDRAW_ALREADY_OPENED') {
                            this.closeModal()
                            this.$root.$emit('open-modal', { modal: 'notify', message: this.$t('withdraw_disabled') })
                            this.$root.$emit('reload-payments')
                        }
                    }
                })
        },

        requestWithdraw() {
            this.loading = true
            // console.log(this.loading)
            var company_id = this.current_company

            if ((this.withdraw_method == ov.PAYMENT_INVOICE) && (this.current_company == 'new')) {
                this.$axios
                    .post('/narrator/companies',
                        this.newcompany
                    )
                    .then((res) => {
                        company_id = res.data.id
                        this.generateWithdrawPayment(company_id)
                        this.loading = false
                    })
            }
            else {
                this.generateWithdrawPayment(company_id)
                this.loading = false
            }
        },

    },

    mounted() {
        this.$root.$on('open-modal', (data) => {
            if (data.modal == this.modal_name) {
                this.loadCompanies()
                this.withdraw_method = data.method
                this.balance = data.balance
                this.sum = data.balance
                this.modal_state = true

                this.$nextTick(() => {
                    window.refreshPage()
                })
            }
        })

        this.$root.$on('close-all-modals', () => {
            this.modal_state = false
        })
    },

    beforeDestroy() {
        this.$root.$off('open-modal')
        this.$root.$off('close-all-modals')
    },

}    
