
import { mapGetters, mapMutations } from 'vuex'
import * as ov from '@/constants/constants'

export default {
    props: [
        'id',
        'text',
        'files',
        'is_new',
        'is_creating_ticket',
        'is_answer',
        'user',
        'supporter',
        'formatted_created_at',
        'ticket_id',
    ],

    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser']),

        userImage() {
            return this.loggedInUser.image ? this.loggedInUser.image : ov.DEFAULT_IMAGE
        },
    },

    data() {
        return {
            message_text: this.text ? this.text : '',
            message_files: this.files ? this.files : [],
            loading: false,
        };
    },

    methods: {

        openFileSelect() {
            var el = this.$refs[`file${this.message_files.length - 1}`][0]
            el.click()
        },

        fileSelected() {
            var i = this.message_files.length - 1
            this.message_files[i].file_name = this.$refs[`file${i}`][0].files[0].name
            this.message_files.push({ file_name: '' })
        },

        removeFile(index) {
            this.message_files.splice(index, 1)
        },

        sendMessage() {
            this.loading = true
            var formData = new FormData();
            formData.append('text', this.message_text)
            this.message_files.forEach((el, i) => {
                if (typeof this.$refs[`file${i}`][0].files[0] !== 'undefined') formData.append("file[]", this.$refs[`file${i}`][0].files[0])
            })

            this.$axios
                .post(`/${this.loggedInUser.role}/tickets/${this.ticket_id}/messages`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                )
                .then((res) => {
                    this.message_text = ''
                    this.message_files = [{ file_name: '' }]
                    this.$emit('message_added', res.data.message)
                    this.loading = false
                })
        },

        startTicket() {
            this.loading = true
            this.$emit('ticket_hide')
            var formData = new FormData();
            formData.append('text', this.message_text)
            this.message_files.forEach((el, i) => {
                if (typeof this.$refs[`file${i}`][0].files[0] !== 'undefined') formData.append("file[]", this.$refs[`file${i}`][0].files[0])
            })

            this.$axios
                .post(`/${this.loggedInUser.role}/tickets`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                )
                .then((res) => {
                    this.message_text = ''
                    this.message_files = [{ file_name: '' }]
                    this.$emit('ticket_added')
                    this.loading = false
                })
        },
    },

    watch: {
        comment_text: function (val, oldVal) {
            this.$emit('change', val)
        },
    },

    mounted() {
        if (this.is_new || this.is_creating_ticket) {
            this.message_files.push({ file_name: '' })
        }

        this.$root.$on('update_ticket_message', (data = null) => {
            if (this.id == data.id) {
                this.message_text = data.text
                this.message_files = data.files
                // this.$forceUpdate()
            }
        })
    },

    beforeDestroy() {
        this.$root.$off('update_order_message')
    },

}
