
import { mapGetters, mapMutations } from 'vuex'
import * as ov from '@/constants/constants'

export default {
    data() {
        return {
            reply_text: null,
            saved_reply: null,
        }
    },

    props: [
        'id',
        'client',
        'narrator',
        'formatted_created_at',
        'formatted_replied_at',
        'text',
        'reply',
        'rating',
        'cliches',
        'shadow',
        'can_reply',
    ],

    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser', 'storeOrder']),

        narratorImage() {
            return this.narrator.image ? this.narrator.image : ov.DEFAULT_IMAGE
        },
    },

    methods: {
        saveReviewReply() {
            this.$axios
                .put('/narrator/save-review-reply',
                    {
                        'id': this.id,
                        'text': this.reply_text,
                    }
                )
                .then((res) => {
                    // console.log(res)
                    this.saved_reply = this.reply_text
                    this.formatted_replied_at = res.data.formatted_replied_at
                    this.$root.$emit('open-modal', { modal: 'notify', message: this.$t('your-reply-sent') })
                })

        },
    },

    created() {
        this.saved_reply = this.reply
    },

}
