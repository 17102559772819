
import { mapGetters, mapMutations } from 'vuex'
import * as ov from '@/constants/constants'

export default {

    props: [
        'checklist',
    ],

    data() {
        return {
            // modal_state: false,
            // modal_name: 'narrator-settings',

            ready_to_view: false,

            narrator: {
                firstname: null,
                lastname: null,
                email: null,
                city: null,
                birthdate: null,
                image: null,
                urgent: false,
                links: null,
                languages: null,
                genders: null,
                genres: null,
                ages: null,
                timbres: null,
                topics: null,
                equipment: null,
                timezone: null,
                agent_id: null,
            },

            day_active: {
                1: false,
                2: false,
                3: false,
                4: false,
                5: false,
                6: false,
                7: false,
            },

            wrongImage: false,
        }
    },

    methods: {
        saveNarrator() {
            this.$axios
                .put('/narrator/common',
                    {
                        firstname: this.narrator.firstname,
                        lastname: this.narrator.lastname,
                        email: this.narrator.email,
                        birthdate: this.narrator.birthdate,
                        city: this.narrator.city,
                        description: this.narrator.description,
                        links: this.narrator.links,
                        urgent: this.narrator.urgent,
                        timetable: this.narrator.timetable,
                        equipment: this.narrator.equipment,
                        languages: this.narrator.languages,
                        topics: this.narrator.topics,
                        genres: this.narrator.genres,
                        genders: this.narrator.genders,
                        ages: this.narrator.ages,
                        timbres: this.narrator.timbres,
                        timezone: this.narrator.timezone,
                    }
                )
                .then((res) => {
                    // this.$root.$emit('close-all-modals')
                    // this.$root.$emit('open-modal', { modal: 'notify', message: this.$t('data-saved-successfully') })
                    // this.$root.$emit('refresh-narrator')
                    this.$emit('updated')
                })

        },

        toggleDay(index) {
            if (this.day_active[index]) {
                this.narrator.timetable['day' + index + '1'] = null
                this.narrator.timetable['day' + index + '2'] = null
            }
            this.day_active[index] = !this.day_active[index]
        },

        addLanguage() {
            var narrator_languages = this.narrator.languages.map(item => item.id)
            var next = Object.values(this.appInfo.languages).find(item => !narrator_languages.includes(item.id))
            if (!next) return false

            this.narrator.languages.push({
                id: next.id,
                pivot: {
                    main: false,
                    native: false
                }
            })
        },

        addTopic() {
            var narrator_topics = this.narrator.topics.map(item => item.id)
            var next = Object.values(this.appInfo.topics).find(item => !narrator_topics.includes(item.id))
            if (!next) return false

            this.narrator.topics.push({
                id: next.id,
            })
        },

        addGender() {
            var narrator_genders = this.narrator.genders.map(item => item.id)
            var next = Object.values(this.appInfo.genders).find(item => !narrator_genders.includes(item.id))
            if (!next) return false

            var main = !this.narrator.genders.length
            this.narrator.genders.push({
                id: next.id,
                pivot: {
                    main: main,
                }
            })
        },

        addAge() {
            var narrator_ages = this.narrator.ages.map(item => item.id)
            var next = Object.values(this.appInfo.ages).find(item => !narrator_ages.includes(item.id))
            if (!next) return false

            var main = !this.narrator.ages.length
            this.narrator.ages.push({
                id: next.id,
                pivot: {
                    main: main,
                }
            })
        },

        addTimbre() {
            var narrator_timbres = this.narrator.timbres.map(item => item.id)
            var next = Object.values(this.appInfo.timbres).find(item => !narrator_timbres.includes(item.id))
            if (!next) return false

            var main = !this.narrator.timbres.length
            this.narrator.timbres.push({
                id: next.id,
                pivot: {
                    main: main,
                }
            })
        },

        addGenre() {
            var narrator_genres = this.narrator.genres.map(item => item.id)
            var next = Object.values(this.appInfo.register_genres).find(item => !narrator_genres.includes(item.id))
            if (!next) return false

            var main = !this.narrator.genres.length
            this.narrator.genres.push({
                id: next.id,
                pivot: {
                    main: main,
                }
            })
        },

        openFileSelect() {
            var input = this.$refs.avatarInput
            input.click()
        },

        fileSelected() {
            var selectedFile = document.querySelector('.avatar-input')
            // console.log(selectedFile.files[0])

            if (selectedFile.files[0].size > 1024 * 1024 * 2) {
                selectedFile.value = ''
                this.wrongImage = true
                return
            }

            if ((selectedFile.files[0].type != 'image/jpeg') && (selectedFile.files[0].type != 'image/png')) {
                selectedFile.value = ''
                this.wrongImage = true
                return
            }

            var formData = new FormData();
            formData.append("file", selectedFile.files[0]);
            this.$axios
                .post('/narrator/avatar',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                )
                .then((res) => {
                    // console.log(res)
                    this.narrator.image = res.data.image
                    this.$root.$emit('refresh-narrator')
                })
        },

    },

    mounted() {
        this.$axios
            .get('/narrator/common')
            .then((res) => {
                this.narrator = res.data.narrator

                for (var i = 1; i <= 7; i++) {
                    this.day_active[i] = this.narrator.timetable['day' + i + '1'] && this.narrator.timetable['day' + i + '2']
                }

                if (!this.narrator.languages.length) this.narrator.languages.push({
                    id: 1,
                    pivot: {
                        main: true,
                        native: true
                    }
                })


                this.$nextTick(function () {
                    this.ready_to_view = true
                })
            })


    },

    computed: {
        ...mapGetters(['loggedInUser', 'isAuthenticated', 'appInfo']),

        narratorImage() {
            return this.narrator.image ? this.narrator.image : ov.DEFAULT_IMAGE
        },

        filledPersonal() {
            return this.narrator.firstname
                && this.narrator.lastname
                && (this.narrator.email || this.narrator.agent_id)
                && this.narrator.city
            // && this.narrator.birthdate
        },

        filledAbout() {
            return this.narrator.description
                && this.narrator.ages.length
                && this.narrator.timbres.length
                && this.narrator.genders.length
        },

        filledWorkhours() {
            // console.log(this.narrator.timetable)
            let arr = Object.values(this.narrator.timetable)
            // console.log(arr)
            arr.shift()
            // console.log(!!(this.narrator.timezone                && arr.some(item => item)))
            return !!(this.narrator.timezone && arr.some(item => item))
            // && (arr.findIndex(item => item) + 1)
        },

        selectedDays() {
            var arr = Object.values(this.day_active)
            return (arr.findIndex(item => item) + 1)
        },

        filledSpecialisaion() {
            return this.narrator.genres.length ? true : false
        },

        filledNotRead() {
            return this.narrator.topics.length ? true : false
        },

        filledLanguages() {
            return this.narrator.languages.length ? true : false
        },

        filledEquipment() {
            return this.narrator.equipment.length ? true : false
        },

        filledAll() {
            return this.filledPersonal
                && this.filledAbout
                && this.filledWorkhours
                && this.filledSpecialisaion
                // && this.filledNotRead
                && this.filledLanguages
                && this.filledEquipment
        },
    }
}    
