
import { mapGetters } from 'vuex'
import * as ov from '@/constants/constants'

export default {
    props: [
        'register',
        'text',
        'role',
        'inline_form'
    ],

    data() {
        return {
            var_role: this.role,
            phone_number: '',
            email: '',
            login_method: ov.LOGIN_BY_PHONE,
            step: 1,
            recallTimer: null,
            timeleft: 120 - 1,
            invalid_code: false,
            is_new_if_its_client: false,

            digits: ["", "", "", ""],
        }
    },

    computed: {
        ...mapGetters(['loggedInUser']),
        ov() {
            return ov
        },

        code() {
            return this.digits.join('')
        },

        isNotValidCredential() {
            if (this.login_method == ov.LOGIN_BY_PHONE) return this.phone_number.length <= 15
            if (this.login_method == ov.LOGIN_BY_EMAIL) return !this.email
        },
    },

    methods: {
        goStep(step) {
            this.step = step
            if (step == 1) {
                this.$nextTick(function () {
                    if (this.login_method == ov.LOGIN_BY_PHONE) this.$refs.phone_number.focus()
                    if (this.login_method == ov.LOGIN_BY_EMAIL) this.$refs.email.focus()
                })
            }

            if (step == 2) {
                this.requestCode()
                this.$nextTick(function () {
                    this.$refs.code1.focus()
                })
            }
        },

        requestCode() {
            this.is_new_if_its_client = false
            this.stopRecallTimer()
            this.setRecallTimer()
            this.$axios
                .get('/request-code',
                    {
                        params: {
                            login_method: this.login_method,
                            role: this.var_role,
                            email: this.email,
                            phone: this.phone_number,
                            debug: this.$route.query.debug ? 'Y' : 'N'
                        }
                    })
                .then((res) => {
                    if (res.data.is_agent && (this.var_role == 'narrator')) this.var_role = 'agent'
                    if (res.data.is_new_if_its_client) this.is_new_if_its_client = true
                })
        },

        setRecallTimer() {
            this.timeleft = 120 - 1;
            this.recallTimer = setInterval(this.timerFunction, 1000);
        },

        timerFunction() {
            if (this.timeleft <= 0) {
                clearInterval(this.recallTimer);
            }
            this.timeleft -= 1;
        },

        stopRecallTimer() {
            clearInterval(this.recallTimer);
        },

        async login() {
            try {
                await this.$auth
                    .loginWith(this.var_role, {
                        data: {
                            login_method: this.login_method,
                            phone: this.phone_number,
                            email: this.email,
                            code: this.code,
                            role: this.var_role,// ? this.var_role : (this.register ? 'narrator' : null),
                            referal_url: getCookie('referal_url'),
                        }
                    })
                    .then((res) => {
                        if ((this.var_role == 'narrator') && !res.data.is_exists) {
                            this.$emit('go_register')
                        }
                        else {
                            this.$emit('done', { role: this.var_role })
                            if ((this.var_role == 'client') && this.is_new_if_its_client) {
                                if (typeof ym !== 'undefined') ym(94196688, 'reachGoal', 'client_register');
                                // console.log('client_register')
                            }

                        }
                    })
            } catch (e) {
                // console.log(e)
                this.error = e.response.data.error
                this.invalid_code = true
                setTimeout(this.resetCode, 3000);
                // return
            }
        },

        moveFocus(index) {
            this.digits = this.digits.join('').replace(/\D/g, '').substring(0, 4).split('')

            if (index < 3 && this.digits[index] && this.digits[index].length === 1) {
                this.$nextTick(() => {
                    this.$refs[`code${index + 2}`].focus();
                });
            }

            if (this.digits.length == 4) {
                this.login()
            }
        },

        keydown(event, index) {
            if (event.code == 'ArrowLeft' && index > 0) {
                this.$nextTick(() => {
                    this.$refs[`code${index + 1 - 1}`].focus();
                    return false
                });
            }
            if (event.code == 'ArrowRight' && index < 3) {
                this.$nextTick(() => {
                    this.$refs[`code${index + 1 + 1}`].focus();
                    return false
                });
            }
            if (event.code == 'Backspace' || event.code == 'Delete') {
                this.$nextTick(() => {
                    this.resetCode()
                });
            }
        },

        resetCode() {
            this.invalid_code = false
            this.digits = []
            this.$refs.code1.focus()
        },
    },

    mounted() {
        this.$nextTick(function () {
            if (this.$refs.phone_number) this.$refs.phone_number.focus()
        })
    },

    watch: {
        phone_number: function (val, oldVal) {
            const prefixNumber = (str) => {
                if (str === "7") {
                    return "7 ";
                } else if (str === "8") {
                    return "7 ";
                } else if (str === "9") {
                    return "7 9";
                }
                return str;
            };

            const value = this.phone_number.replace(/\D+/g, "");
            const numberLength = 11;

            let result;
            if (this.phone_number.includes("+8") || this.phone_number[0] === "8") {
                result = "+";
            } else {
                result = "+";
            }

            for (let i = 0; i < value.length && i < numberLength; i++) {
                switch (i) {
                    case 0:
                        result += prefixNumber(value[i]);
                        continue;
                    case 4:
                        result += " ";
                        break;
                    case 7:
                        result += "-";
                        break;
                    case 9:
                        result += "-";
                        break;
                    default:
                        break;
                }
                result += value[i];
            }
            this.phone_number = result;
        },
    },
}    
