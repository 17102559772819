
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
        }
    },

    computed: {
        ...mapGetters(['loggedInUser']),
    },

    methods: {
        closeModal() {
            this.$root.$emit('close-all-modals')
        },

        successLogin() {
            this.closeModal()
            this.$root.$emit('save_order',1)
        },
    }
}    
