import { render, staticRenderFns } from "./OrderOptions.vue?vue&type=template&id=4b86b9f6"
import script from "./OrderOptions.vue?vue&type=script&lang=js"
export * from "./OrderOptions.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrderOption: require('/var/www/frontend/components/ui/OrderOption.vue').default})
