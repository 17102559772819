
export default {
    props: [
        'init_narratorcomps',
        'init_genres',
        'emitInsteadOpen',
        'popup',
        'init_active_genre',
    ],

    data() {
        return {
            narratorcomps: null,
            genres: null,
            active_genre: this.init_active_genre,
        };
    },

    created() {
        if (!this.init_narratorcomps) {
            this.$axios
                .get('/narratorcomps')
                .then((res) => {
                    this.narratorcomps = res.data.narratorcomps
                    this.genres = res.data.genres
                })
        }
        else {
            this.narratorcomps = this.init_narratorcomps
            this.genres = this.init_genres
        }
    },

    methods: {
        setGenre(genre_id) {
            this.active_genre = genre_id
            if (!this.popup) {
                const genre = this.genres.find(item => item.id == genre_id)
                history.pushState(null, null, this.localePath('compilations-genre') + '/' + genre.slug)
                this.$emit('genre_change', genre)
            }
        }
    },
}
