
import { mapGetters } from 'vuex'
import * as ov from '@/constants/constants'

export default {
    data() {
        return {
            modal_state: false,
            modal_name: 'soundengineer-settings',

            soundengineer_settings: {
                firstname: this.$store.getters.loggedInUser ? this.$store.getters.loggedInUser.firstname : null,
                lastname: this.$store.getters.loggedInUser ? this.$store.getters.loggedInUser.lastname : null,
                email: this.$store.getters.loggedInUser ? this.$store.getters.loggedInUser.email : null,
                image: this.$store.getters.loggedInUser ? this.$store.getters.loggedInUser.image : '',
                // genres: Object.values(this.$store.getters.loggedInUser.genres),
                genres: this.$store.getters.loggedInUser ? this.$store.getters.loggedInUser.genres : null,
            },

            wrongImage: false,

        }
    },

    computed: {
        ...mapGetters(['loggedInUser', 'appInfo']),

        soundengineerImage() {
            return this.soundengineer_settings.image ? this.soundengineer_settings.image : ov.DEFAULT_IMAGE
        },
    },

    methods: {
        closeModal() {
            this.$root.$emit('close-all-modals')
        },

        saveSoundengineer() {
            this.$axios
                .put('/soundengineer/' + this.loggedInUser.id,
                    this.soundengineer_settings
                )
                .then((res) => {
                    // console.log(res)
                    this.$store.commit('updateUserData', this.soundengineer_settings)
                    this.closeModal()
                })
        },

        openFileSelect() {
            var input = this.$refs.avatarInput
            input.click()
        },

        fileSelected() {
            var selectedFile = document.querySelector('.avatar-input')

            if (selectedFile.files[0].size > 1024 * 1024 * 2) {
                selectedFile.value = ''
                this.wrongImage = true
                return
            }

            if ((selectedFile.files[0].type != 'image/jpeg') && (selectedFile.files[0].type != 'image/png')) {
                selectedFile.value = ''
                this.wrongImage = true
                return
            }

            var formData = new FormData();
            formData.append("file", selectedFile.files[0]);
            this.$axios
                .post('/soundengineer/avatar',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                )
                .then((res) => {
                    // console.log(res)
                    this.soundengineer_settings.image = res.data.image
                    // this.$root.$emit('refresh-soundengineer')
                    this.$store.commit('updateUserData', this.soundengineer_settings)
                })
        },

        addGenre() {
            var soundengineer_genres = this.soundengineer_settings.genres.map(item => item.id)
            var next = Object.values(this.appInfo.register_genres).find(item => !soundengineer_genres.includes(item.id))
            if (!next) return false

            var user_genres = Object.values(this.soundengineer_settings.genres)
            user_genres.push({
                id: next.id,
            })
            this.soundengineer_settings.genres = user_genres
        },

        deleteGenre(id) {
            var user_genres = Object.values(this.soundengineer_settings.genres)
            var index = user_genres.findIndex(item => item.id == id)
            user_genres.splice(index, 1)
            this.soundengineer_settings.genres = user_genres
        },

        changeGenre(item, index, $event) {
            var user_genres = Object.values(this.soundengineer_settings.genres)
            // console.log(this.ids(user_genres))
            user_genres[index] = $event
            this.soundengineer_settings.genres = user_genres
        },

        ids(obj) {
            return Object.values(obj).map(item => item.id)
        },
    },

    mounted() {
        this.$root.$on('open-modal', (data) => {
            if (data.modal == this.modal_name) {
                this.modal_state = true
            }
        })

        this.$root.$on('close-all-modals', () => {
            this.modal_state = false
        })
    },

    beforeDestroy() {
        this.$root.$off('open-modal')
        this.$root.$off('close-all-modals')
    },

}    
