export default () => ({

  appinfo: null,

  state_timer: null,
  pause_timer: null,

  home: null,
  events: null,

  order: {
    narrator: {
      id: null,
      firstname: null,
      lastname: null,
      image: null,
      urgent: 1,
      prices: {
        urgent: 50,
      },
      comment: null,
    },
    prevstatus_id: null,

    title: null,
    text: null,

    words_count: 0,
    time_optimal: 0,
    time_slow: 0,
    time_fast: 0,

    genre: {
      id: 1,
    },
    // mood: 2,
    // speed: 2,
    topic: 0,
    urgent: 0,
    price: null,
    file: null,
    file_id: null,
    comment: null,
    musics: [],

    cleaning: false,
    mixing: false,
    alienation: false,
    federal: false,

    clip_length: 0,

  },

  order_protected: false, //защита от обнуления при загрузке черновика или переходе в несохраненный новый заказ

})