
import { mapGetters, mapMutations } from 'vuex'

export default {
    props: [
        'mobile_menu_shown',
    ],
    // data() {
    //     return {
    //         mobile_menu_shown: false,
    //     };
    // },
    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser', 'appInfo']),

    },
    methods: {
        async logout() {
            await this.$auth.logout();
        },

        isBlogPost() {
            if (this.$route.params.category && this.$route.params.post) return true
            return false
        },

        isProfile() {
            if (this.$route.path.indexOf(this.localePath('profile')) != -1) return true
            return false
        },

        isProfileSupportTicketsDetail() {
            if ((this.$route.path.indexOf(this.localePath('profile-support-tickets')) != -1) && (this.$route.params.ticket)) return true
            return false
        },

        isNoHeader() {
            return (this.$route.path != this.localePath('become-narrator'))
                && !this.isBlogPost()
                && !this.isProfile()
                && !this.isProfileSupportTicketsDetail()
                && (this.$route.path != this.localePath('help'))
                && (!this.$route.params.narrator)
                && (this.$route.path != this.localePath('new-order'))
        },

        isNewOrder() {
            return (this.$route.path == this.localePath('new-order'))
        },

        toggleMobileMenu() {
            this.$emit('input', !this.mobile_menu_shown)
        },

    },
}
