
export default {
    props: [
        'value',
        'genre',
    ],

    data() {
        return {
            sorts: {
                0: { id: 0, title: this.$t('by-default') },
                1: { id: 1, title: this.$t('new-first') },
                2: { id: 2, title: this.$t('cheap-first') },
                3: { id: 3, title: this.$t('expensive-first') },
                4: { id: 4, title: this.$t('alphabet-asc') },
                5: { id: 5, title: this.$t('alphabet-desc') },
            },
            price_sort: this.value,
        };
    },

    computed: {
        hidden_sorts() {
            return (this.genre == 0 ? Object.values({ 2: { 'id': 2 }, 3: { 'id': 3 } }) : [])
        },
    },

    watch: {
        value: function (val, oldVal) {
            this.price_sort = val
        },
        price_sort: function (val, oldVal) {
            // console.log('Sort price_sort changed', oldVal, val)
            this.$emit('update', val)
            this.$emit('input', val)
        },
        genre: function (val, oldVal) {
            // console.log('Sort genre changed', oldVal, val)
            if ((this.genre == 0) && this.price_sort && [2, 3].includes(this.price_sort)) {
                this.price_sort = 0
                // console.log('Sort price_sort=0', oldVal, val)
            }
        },
    },

}
