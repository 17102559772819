import { render, staticRenderFns } from "./NarratorPricesInside.vue?vue&type=template&id=ab267698"
import script from "./NarratorPricesInside.vue?vue&type=script&lang=js"
export * from "./NarratorPricesInside.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Select: require('/var/www/frontend/components/ui/Select.vue').default})
