
import { mapGetters } from 'vuex'
import * as ov from '@/constants/constants'

export default {

    props: [
        'narratorcomp_mode',
        'initial_genre',
        'initial_genders',
        'initial_ages',
        'initial_timbres',
        'initial_language',
        'initial_native',
        'initial_name',
        'initial_sort',
    ],

    data() {
        return {
            filter_genre: this.initial_genre,
            filter_genders: this.initial_genders ? this.initial_genders : [],
            filter_ages: this.initial_ages ? this.initial_ages : [],
            filter_timbres: this.initial_timbres ? this.initial_timbres : [],
            filter_language: this.initial_language ? this.initial_language : 0,
            filter_native: this.initial_native ? this.initial_native : false,
            filter_featured: false,
            filter_topic: 0,
            filters_shown: false,
            filter_sort: this.initial_sort ? this.initial_sort : 0,
            filter_name: this.initial_name ? this.initial_name : '',
            timerBeforeSearch: null,
            filter_dummy: 0,
        };
    },

    computed: {
        ...mapGetters(['appInfo']),
        ov() {
            return ov
        },

        filter_options_sum() {
            return this.filter_ages +
                this.filter_genre +
                this.filter_genders +
                this.filter_timbres +
                this.filter_native +
                this.filter_language +
                this.filter_featured +
                this.filter_topic +
                this.filter_sort +
                this.filter_name +
                this.filter_dummy
        },

        languages_for_select() {
            var arr = JSON.parse(JSON.stringify(this.appInfo.languages))
            var arr2 = Object.values(arr)
            arr2.unshift({ id: 0, title: this.$t('all-languages') })
            return arr2
        },

        filter_options() {
            let options = {
                page: 1,
                filter_genre: this.filter_genre,
                filter_genders: this.filter_genders,
                filter_ages: this.filter_ages,
                filter_timbres: this.filter_timbres,
                filter_language: this.filter_language,
                filter_native: this.filter_native,
                filter_featured: this.filter_featured,
                filter_topic: this.filter_topic,
                filter_sort: this.filter_sort,
                filter_name: this.filter_name,
            }

            if (process.client) {
                sessionStorage.options = JSON.stringify(options)

                // setCookie('filter_options', JSON.stringify(options))
            }



            return options
        },
    },

    methods: {
        resetFilters() {
            this.filter_ages = []
            this.filter_genre = 0
            this.filter_genders = []
            this.filter_timbres = []
            this.filter_native = false
            this.filter_language = 0
            this.filter_featured = false
            this.filter_topic = 0
            this.filter_sort = 0
            this.filter_name = ''
        },

        setFilters(data = {}) {
            // console.log('setFilters')
            // console.log(data)
            this.filter_ages = data.filter_ages
            this.filter_genre = data.filter_genre
            this.filter_genders = data.filter_genders
            this.filter_timbres = data.filter_timbres
            this.filter_native = data.filter_native
            this.filter_language = data.filter_language
            this.filter_featured = data.filter_featured
            this.filter_topic = data.filter_topic
            this.filter_sort = data.filter_sort
            this.filter_name = data.filter_name
            this.filter_dummy++
        },

        toggleFilters(state = null) {
            if (state) {
                this.filters_shown = state
                return
            }
            if (!this.filters_shown) {
                this.filters_shown = true; this.$root.$emit('open_filter')
            }
            else {
                this.filters_shown = false; this.$root.$emit('close_filter')
            }
        }
    },

    created() {
        this.$root.$on('set_active_genre', (id) => {
            this.filter_genre = id
        })

        this.$root.$on('set_filter_featured', (state) => {
            this.filter_featured = state
        })

        this.$root.$on('open_filter', () => {
            this.filter_shown = 1
        })

        this.$root.$on('close_filter', () => {
            this.filter_shown = 0
        })

        this.$root.$on('set_filters', (data) => {
            // console.log('root set_filters')
            this.setFilters(data)
        })

    },

    beforeDestroy() {
        this.$root.$off('set_active_genre')
        this.$root.$off('set_filter_featured')
        this.$root.$off('open_filter')
        this.$root.$off('close_filter')
        this.$root.$off('set_filters')
    },

    watch: {
        filter_options_sum: function (val, oldVal) {
            this.$emit('filter_updated', this.filter_options)
        },
        initial_name: function (val, oldVal) {
            clearTimeout(this.timerBeforeSearch);
            this.timerBeforeSearch = setTimeout(() => {
                this.resetFilters()
                this.$emit('filter_updated', this.filter_options)
            }, 1000);

        },
        initial_sort: function (val, oldVal) {
            this.filter_sort = val
        },
        initial_name: function (val, oldVal) {
            this.filter_name = val
        },
    },
}
