export default {
  'home': 'Home',
  'new-order': 'New order',
  'narratorcomps': 'Compilations',
  'narrators': 'Narrators',
  'login': 'Log in',
  'blog': 'Blog',
  'become-narrator': 'Become a narrator',
  'help': 'Help',
  'how-it-works': 'How it works',
  'back': 'Back',
  'about': 'About',
  'privacy-policy': 'Privacy policy',
  'narrator-registration': 'Narrator registration',
  'profile': 'Profile',
  'for-registration': 'To register on the site as an announcer, you need to go to the site using your phone number. We will use the same number for emergency calls. No spam.',
  'personal-data': 'Personal data',
  'left-to-fill': 'Left to fill',
  'no-reviews-yet': 'No reviews yet',
  'finish-register': 'Finish registration',
  'ad-example': 'Ad example',
  'audiobook-example': 'Audiobook example',
  'longread-example': 'Longread example',
  'offframe-example': 'Dubbing Example',
  'sad-example': 'Sad demo',
  'joy-example': 'Joy demo',
  'demo-load-text': 'The more examples of work you upload, the higher the chance that you will be included in different collections and contacted specifically to you',
  'no-payments': 'No payments',
  'no-tickets': 'No tickets',
  'no-orders': 'No orders yet',
  'no-draft-orders': 'No drafts',
  'no-completed-orders': 'No completed orders yet',
  'add-gender': 'Add voice',
  'add-age': 'Add voice age',
  'add-timbre': 'Add voice timbre',
  'i-read-all': 'I read all',
  'other-female': 'Other',
  'no-narrator-reviews': 'This narrator doesn\'t have any reviews yet',
  'to-calc-price': 'Calculate the price',
  'price-calculation': 'Price calculation',
  'calc-order-not-logged-text': 'In order to send files for calculation to the announcer, you need to go to the site using your phone number. We will use the same number for emergency calls. We will not share the number with anyone else. No spam.',
  'ideal-quality': 'Ideal quality of materials',
  'ready-track': 'Ready-track',
  'benefits-text1': 'The process of creating quality audio content is all about the creativity and professionalism of the announcer, screenwriter and sound engineer. We are proud of our team of specialists!',
  'benefits-text2': 'We do not forget about the individual approach, and we know the habits of most of our customers. We remember by heart the e-mail addresses of our regular customers, if possible, we give a discount and try to solve any problem as efficiently as possible.',
  'order-clean-track': 'Order clean track',
  'order-ready-track': 'Order ready track',
  'cancel': 'Cancel',
  'topic-leave-blank': 'Leave blank if none of the given topics are covered',
  'urgent-conditions': 'If an order is created after 5:00 pm, it will be completed within the next business day',
  'no-reviews-yet': 'You have no reviews yet',
  'payment': 'Payment',
  'pay-not-logged-text': 'To order you need to go to the site using your phone number. We will use the same number for emergency calls. We will not share the number with anyone else. No spam.',
  'go-to-payment': 'Go to payment',
  'to-payment': 'To payment',
  'additional-services': 'Additional Services',
  'track-order': 'Track ordering',
  'from-wallet': 'From wallet',
  'you-got-unsaved-order': 'You\'ve got unsaved order',
  'return-to-order': 'Return to order',
  'wallet-balance': 'Wallet balance',
  'pay-from-wallet': 'Pay from wallet',
  'payment-description': 'Payment description',
  'payment-success': 'Payment was successful',
  'payment-rejected': 'Payment was rejected',
  'payment-waiting-for-payment': 'Top-up operation is waiting for payment',
  'error-happened': 'Error was happened. <br>Try again, in case of error contact the support',
  'waiting-payment': 'Waiting for payment',
  'sent': 'Sent',
  'wait-for-approval': 'Wait for approval',
  'write-total-cost': 'Write the total cost of the order',
  'total-cost': 'Total cost',
  'order-accepted': 'Order is accepted',
  'order-approved': 'Order is approved',
  'you-can-start': 'You can start',
  'processing-by-soundengineer': 'Processing by soundengineer',
  'page-not-found': 'Page not found',
  'add-track': 'Add track',
  'withdraw-request-sent': 'Withdrawal request sent successfully, it will be operated soon',
  'register-finished': 'Congratulations, you have finished registration! Now your profile is on moderation, soon you will be able to accept orders',
  'notify_status_4': 'Order was declined by narrator and saved as a draft',
  'notify_status_6': 'Order was cancelled by client',
  'notify_status_101': 'Changing the estimated price is no longer possible since the order has already been accepted and paid by the client.',
  'order-not-found': 'Order not found. Maybe it was cancelled',
  'rate-service': 'Please, rate service quality',
  'leave-review': 'Leave a review',
  'soundengineer-msg1': 'Files in progress',
  'soundengineer-msg2': 'The announcer has submitted the results. Now the sound engineer is cleaning and mixing them. Once processing is complete, the files will appear here.',
  'topup-and-pay-card': 'Top up and pay by card',
  'ok': 'OK',
  'close': 'Close',
  'i-got-it': 'I got it',
  'service-fee': 'service fee',
  'total': 'Total',
  'jpg-png': 'JPEG, PNG, not&nbsp;larger than 2 Mb',
  'send-to-soundengineer': 'Send to soundengineer',
  'not-any-topic': 'Without any topic',
  'topic-default': 'No matters',
  'soundengineer': 'Soundengineer',
  'order-details': 'Order details',
  'alienation-notice': 'At the end of the job, send the completed and signed Alienation Agreement to the client',
  'audiobook-desc': 'Minimal order cost is one hour cost.',
  'audiobook-title': '<strong>1 hour cost</strong> (about 20 pages)',
  '1h': '1 hour',
  'minimum': 'minimum',
  'buhgalteria': 'Accounting',
  'add-options': 'Additional options',
  'price-by-request': 'Price by request',
  'search-by-name': 'Search for narrator',
  'safe-notice': 'If something goes wrong, we will return the money in full',
  'choose-narrator': 'Choose narrator',
  'add-lazy-narrator': 'Add the narrator who <br>doesn\'t want to come himself',
  'change-narrator': 'Change narrator',
  'client-notice-refresh-page': 'Don\'t forget to refresh the page in case your order is ready',
  'agent': 'Agent',
  'its-your-narrator': 'It\'s your narrator',
  'all-messages-will-go-to': 'All messages will go to',
  'payment-safe': 'Safe payment',
  'with-commission': 'with commission',
  'wait': 'Wait please ...',
  'share': 'Share',
  'for-narrators': 'For narrators',
  'for-clients': 'For clients',
  'offer': 'Offer',

  'confirm-all-done': 'I confirm that all is done',
  'narrator-work-not-done': 'Narrator\'s work is not done already',
  'soundengineer-work-not-done': 'Soundengineer\'s work is not done already',
  'narrator-sent-results': 'Narrator sent work results',
  'please-confirm-results': 'Please confirm that work is all done correctly',
  'please-confirm-results-before-se': 'Please confirm that work is done to transfer it to soundengineer',
  'soundengineer-sent-results': 'Soundengineer sent work results',
  'soundengineer-appears-in-chat': 'Soundengineer appears in chat',
  'order-completed': 'Order completed',
  'main-chat': 'Main chat',
  'unstable-site': 'Dear users! After the update, the site may not work very stably; we constantly test its performance and correct shortcomings. If you encounter an error in your work, please write us a ticket in the Support menu or email help@overvoice.online.',

  'narrator_fee_text0': 'Service fee',
  'narrator_fee_text1': 'At a cost of',
  'narrator_fee_text3': 'when withdrawing funds, the remaining amount is',

  'narrator_fee_30s': 'up to 30s of voiceover,',
  'narrator_fee_60s': 'up to 1m of voiceover,',
  'narrator_fee_90s': 'up to 1m 30s of voiceover,',
  'narrator_fee_1p': 'up to 1 page of voiceover,',
  'narrator_fee_5p': 'from 5 pages of voiceover,',

  'as-soundengineer': 'As soundengineer',
  'by-phone': 'By phone',
  'by-email': 'By e-mail',

  //home
  'all-genres': 'All genres',
  'for-narrators': 'For narrators',
  'all-narratorcomps': 'All selections',
  'all-narrators': 'All narrators',
  'narrators-selections': 'Narrators selections',
  'popular-questions': 'Popular questions',
  'home-narrators-count1': 'Over',
  'home-narrators-count2': 'narrators narrating a text for all the genres',
  'home-languages1': 'Native speakers for',
  'home-languages2': 'languages',
  'articles': 'Articles',
  'add-narrator': 'Add narrator',
  'add-text': 'Add text',
  'attach-text': 'Attach text',
  'attach-file-with-text': 'attach file with text',
  'file': 'file',
  'words-count': 'Words',
  'seconds-count': 'Seconds',
  'link': 'Link',
  'or-link-to-file': 'or-link-to-file',
  'online-service': 'Narrating order online service',
  'delete-link': 'Delete link',
  'insert-file-link': 'Insert-file-link',
  'narrators-24/7': 'Narrators 24h everyday',
  'narrators-24/7-text': 'If you need "yesterday", we will tear to shreds, but we will do the project right on time. There are times when the video should have been on the air in 10 minutes. We dealt with it. Sounds incredible, but true.',
  'narrating-examples': 'Narrating examples',
  'madeby1': 'Made by',
  'madeby2': '',
  'madeby3': '',
  'as-client': 'As a client',
  'as-narrator': 'As a narrator',

  //new-order
  'music': 'Music',
  'to-genres': 'To genres',
  'compositions-plural': 'compositions | composition | compositions | compositions',
  'add': 'Add',
  'remove': 'Remove',
  'project-name': 'Project name',
  'add-music': 'Add music',
  'add-comment-2': 'Add comment',
  'speed-medium': 'Optimal',
  'speed-slow': 'Slow',
  'speed-fast': 'Fast',
  'featured-narrators': 'Featured',
  'cheap-first': 'Cheapest first',
  'expensive-first': 'Most expensive first',
  'new-first': 'Recent added first',
  'alphabet-asc': 'By alphabet A-Z',
  'alphabet-desc': 'By alphabet Z-A',
  'by-default': 'By default',
  'genre-other': 'Other',
  'mood-to-read': 'Mood to read',
  'read-speed': 'Reading speed',
  'when-result': 'When you need results',
  'during-day': 'During business hours',
  'check-list': 'Check-list',
  'narrator-selected': 'Narrator selected',
  'save-draft': 'Save to draft',
  'send-for-calc': 'Send for calculation',
  'accents-right': 'Accents in the file are correct',
  'narrator-not-urgent': 'This speaker will not be able to make the task during an hour',
  'music-added': 'Music added',
  'change-time': 'Change time',
  'change-narrator': 'Change narrator',
  'to-time': 'Time',
  'add-services': 'Add services',
  'option-cleaning': 'Track cleaning',
  'option-mixing': 'Mixing',
  'duration': 'Duration',
  'order-done': 'Order completed',
  'save-draft-text': 'In order to save the project, you need to go to the site using your phone number. We will use the same number for emergency calls. We will not share the number with anyone else. No spam.',
  'narrator': 'Narrator',
  'cannot-urgent': ' cannot take the job within the given time frame.',
  'no-current-orders': 'No current orders in work now',
  'clip-length': 'Clip duration',
  'delete-file': 'Remove file',
  'comment': 'Comment',
  'narrator_choose': 'Narrator choose it',
  'chosen': 'Chosen',
  'change-topic': 'Change topic',
  'cannot-topic': ' don\'t work with this topic',

  //narratorcomps
  'diktorov': 'narrators | narrator | narrators | narrators',
  'copy-link': 'Copy link',
  'copied': 'Copied!',
  'download': 'Download',

  //narrators
  'show-more': 'Show more',
  'filters': 'Filters',
  'age': 'Age',
  'language': 'Language',
  'genre': 'Genre',
  'gender': 'Gender',
  'all-genders': 'Any gender',
  'all-ages': 'Any age',
  'all-languages': 'Any language',
  'native': 'Native speaker',
  'select': 'Select',
  'main': 'Main',
  'main2': 'Main',
  'reset': 'Reset',
  'reset-filters': 'Reset filters',
  'about-narrator': 'About narrator',
  'prices': 'Prices',
  'reviews': 'Reviews',
  'demo': 'Demo',
  'select-narrator': 'Select narrator',
  'voice-chars': 'Voice characteristics',
  'voice': 'Voice',
  'voice-timbre': 'Voice timbre',
  'voice-age': 'Voice age',
  'about-myself': 'About myself',
  'about-me': 'About me',
  'workhours': 'Work hours',
  'from': 'From',
  'till': 'Till',
  'ready-for-immediate-orders': 'Ready for immediate orders',
  'specialization': 'Speciality',
  'main-spec': 'Main',
  'native0': 'Native',
  'languages': 'Languages',
  'narrator-not-read': 'Narrator won\'t read',
  'equipment': 'Equipment',
  'main-demo': 'Main demo',
  'work-examples': 'Work examples',
  'mood-examples': 'Mood examples',
  'all-reviews': 'All reviews',
  'to-order': 'Order',
  'topic': 'Topic',
  'all-topics': 'Can read all',

  //blog    
  'all-posts': 'All posts',
  'read-more': 'Read more',
  'load-more': 'Load more',
  'other-articles': 'Other articles',
  'other-guides': 'Other guides',

  //become
  'registration': 'Register',


  //profile
  'logout': 'Log out',
  'support': 'Support',
  'my-orders': 'My orders',
  'wallet': 'Wallet',
  'my-profile': 'My profile',
  'you-on-moderate': 'Your account is under review. As soon as this message disappears, you will be able to take orders.',
  'data-changed-successfully': 'Data changed successfully',
  'data-saved-successfully': 'Data saved successfully',
  'you-get-call-to-email': 'We sent you code to your email address',
  'if-no-email-check-spam': 'If email is absent, please check spam folder also',
  //--settings
  'edit-profile': 'Edit profile',
  'edit': 'Edit',
  'save': 'Save',
  'firstname': 'First name',
  'lastname': 'Last name',
  'add-photo': 'Add photo',
  'change-photo': 'Change photo',
  'your-email': 'E-mail',
  'enter-firstname': 'Enter your first name',
  'enter-lastname': 'Enter your last name',
  'enter-email': 'mail@example.com',
  'enter-city': 'Enter your city',
  'what-city': 'From what city',
  'birthdate': 'Date of birth',
  'reply': 'Reply',
  'add-equipment': 'Add equipment',
  'tell-about-yourself': 'Tell us briefly about yourself',
  'links-if-present': 'Links, if any',
  'day1': 'MO',
  'day2': 'TU',
  'day3': 'WE',
  'day4': 'TH',
  'day5': 'FR',
  'day6': 'SA',
  'day7': 'SU',
  'timezone': 'Timezone',
  'add-language': 'Add language',
  'add-topic': 'Add topic',
  'in-30min': 'Within 30 minutes of receiving the order',
  'upload-demo': 'Upload demo',
  'work-cost-edit': 'Work cost edit',
  'demo-edit': 'Demo edit',
  'timbre': 'Timbre',
  'speed': 'Speed',
  'narration-speed': 'Narration speed',
  'example': 'Example',
  'your-reply-sent': 'Your reply was sent',
  'insert-link': 'Insert link',
  'ad': 'Advertising',
  'ad-desc': 'Promotional video, game video, informational',
  'long-desc': 'Audioguide, presentation, instruction, IVR&nbsp;autoresponder',
  'upto-30s': 'Up to 30 seconds',
  'upto-1m': 'Up to 1 minute',
  'upto-1m30s': 'Up to 1 minute 30 seconds',
  'commercial': 'Commercial',
  'commercial-desc': 'Audioguide, brand ad, presentation',
  'upto-1p': 'Up to 1 page A4 14 font',
  'from-5p': 'From 5 pages',
  'for-1p': 'for 1 page',
  'non-commercial': 'Non-commercial',
  'non-commercial-desc': 'For example, an audio guide',
  'longread': 'Longread',
  'off-frame': 'Off-frame',
  'off-frame-desc': 'Film, series, cartoon, doc. movie, youtube',
  '1min': '1 min',
  'not-less': 'Not less than',
  'off-frame-desc2': 'The bid is based on the length of the entire movie, regardless of the amount of text.',
  'audiobook': 'Audiobook',
  'upto-1h': 'Up to 1h',
  'coeffs': 'Increasing coefficients',
  'coeff-parody': 'Parody',
  'option-alienation': 'Alienation of rights',
  'coeff-urgent': 'During an hour order',
  'coeff-federal': 'Federal Roller',
  'enter-cost': 'Enter cost',
  'item-for-1min': 'Item for 1 minute',
  'video-not-less': 'Video is at least long',
  'increasing-factors': 'Increasing Factors',
  'by-topics': 'By topics',
  'enter-percent': 'Enter percent',
  'enter-minutes': 'Enter minutes count',
  'off-frame-calc': 'At cost <strong>{0} ₽</strong> for 1 min. <strong>{1} min.</strong> video voice acting will be priced at <strong>{2} ₽</strong> regardless of the amount of text.',
  'federal-calc': 'If the normal rate is {0} ₽ up to 1 min, then the <strong>+{1}%</strong> increase will be <strong>{2} ₽</strong> up to 1 min. ',
  'equipment-name': 'Equipment name',
  'add-genre': 'Add genre',
  'remove-pause': 'Remove pause',
  'take-pause': 'Take pause in work',
  'pause-1h': '1 hour',
  'pause-3h': '3 hours',
  'pause-1d': '1 day',
  'pause-undefined': 'Indefinite term',
  'narrator-work-price': 'Narrator work\'s price',


  //---orders
  'orders-inwork': 'Orders in process',
  'orders-completed': 'Completed',
  'orders-all': 'All orders',
  'orders-drafts': 'Drafts',
  'completed': 'Completed',
  'draft': 'Draft',
  'inwork': 'In work',
  'urgent': 'During an hour',
  'cancel-order': 'Cancel order',
  'delete-order': 'Delete order',
  'delete-draft': 'Delete draft',
  'today': 'Today',
  'yesterday': 'Yesterday',
  'accept-order': 'Accept order',
  'reject-order': 'Reject',
  'price-correction-needed': 'Price clarification required',
  'result': 'Result',
  'expand-all-text': 'Expand all text',
  'add-comment': 'Add comment',
  'attach': 'Attach',
  'send': 'Send',
  'send_to_soundengineer': 'Send to soundengineer',
  'pay': 'Pay',
  'text': 'Text',
  'wait-calc': 'Waiting for a calculation',
  'narrator-sent-result': 'Narrator sent result',
  'order-is-completed': 'Order is completed',
  'narrator-not-calculated-yet': 'Narrator haven\'t calculated order cost yet',
  'goal': 'Goal',
  'mood': 'Mood',
  'final-cost': 'Final cost',
  'min': 'min',
  'order-wait-approve': 'Waiting for approve',
  'order-wait-verification': 'Waiting for verification',
  'first-order1': 'Congratulations on your first order!',
  'first-order2': 'This screen will collect all your orders. Click on one of them to view its details.',
  'order-in-work': 'Order is in work',
  'waiting-narrator-accept': 'Wait for narrator accept order',
  'note-links-not-eternal': 'To avoid losing the linked files one day, it\'s better to download them. Links are not permanent.',
  'order-file-too-big': `<strong>The file is too big</strong>. Attach a file no larger than 100 MB.
  If the file is larger than 100 MB, use a file sharing service
  (for example, <a target="_blank" rel="nofollow" href="https://disk.yandex.ru">Yandex Disk</a>)
  and attach a link to the file.`,
  'delete': 'Delete',
  'narrator-result-message1': 'Announcer has sent the results of the work',
  'narrator-result-message2': 'Please check the work and confirm if the task was completed correctly.',
  'narrator-result-message3': 'Work not done yet',
  'narrator-result-message4': 'Verify, done',
  'comment-added': 'Comment added',
  'se-result-message1': 'Soundengineer has processed files',

  //---wallet
  'balance': 'Balance',
  'top-up-wallet': 'Top up wallet',
  'card-online': 'Card online',
  'payment-by-invoice': 'Payment by invoice',
  'withdraw-funds': 'Withdraw funds',
  'payments-history': 'Payments history',
  'date': 'Date',
  'method': 'Method',
  'sum': 'Sum',
  'status': 'Status',
  'document': 'Document',
  'top-up-wallet': 'Top up wallet',
  'card-online': 'Card online',
  'payment-by-invoice': 'Payment by invoice',
  'withdraw-funds': 'Withdraw funds',
  'by-card-number': 'By card number',
  'by-invoice-number': 'By invoice number',
  'to-cryptowallet': 'To cryptowallet',
  'withdraw-sum': 'Withdraw sum',
  'top-up-sum': 'Top up sum',
  'act': 'Act',
  'receipt': 'Receipt',
  'invoice-for-payment': 'Invoice for payment',
  'top-up': 'Top up',
  'new-legal-entity': 'New legal entity',
  'generate-and-download-invoice': 'Generate and download invoice',
  'please-fill-requisites': 'Your wallet will be replenished with the specified amount as soon as the money arrives in our account. To speed up the process, you can send a payment order to the address ',
  'please-fill-requisites2': 'You can specify any amount to replenish your wallet to make ordering speakers faster and easier in the future.',
  'enter-sum': 'Enter sum',
  'bank-corr-account': 'Bank correspondent account',
  'full-company-name': 'Full company name',
  'inn': 'INN',
  'legal-address': 'Legal address',
  'send-withdrawal-request': 'Send withdrawal request',
  'card-number': 'Card number',
  'enter-inn': 'Enter INN',
  'enter-address': 'Enter address',
  'enter-bik': 'Enter BIK',
  'enter-company-name': 'Enter company name',
  'enter-bank-name': 'Enter bank name',
  'enter-corr-account': 'Enter correspondent account number',
  'enter-or-paste-number': 'Enter or paste number',
  'enter-number': 'Enter number',
  'bank-name': 'Bank name',
  'current-account-number': 'Current account number',
  'cryptowallet-number': 'Cryptowallet number',
  'bik': 'BIK',
  'invoice-generated': 'Invoice has been generated',

  //support
  'tickets': 'Tickets',
  'write-to-support': 'Write to support',
  'guides': 'Guides',
  'no-answer1': 'No answer for your question?',
  'no-answer2': 'Write to us.',
  'help-mail': 'help@overvoice.online',
  'created_at': 'Created',
  'updated_at': 'Updated',
  'attach-file': 'attach file',
  'ticket-for-support': 'Ticket for support',
  'enter-ticket-text': 'Enter ticket text',
  'ticket': 'Ticket',
  'new-ticket-created': 'Your ticket was opened',
  'your-message-sent': 'Your message was sent',
  'enter-answer': 'Enter answer',

  //modals
  'excellent': 'I got it',

  //--login
  'login-to-site': 'Log in',
  'phone-number': 'Phone number',
  'get-code': 'Get the code',
  'agree-text1': 'By continuing, you agree to',
  'agree-link-text': 'the collection and processing of personal data and user agreement',
  'you-get-call-to': 'You will receive a call to',
  'change': 'Change',
  'last-4-digits': 'The last 4 digits of the calling number are your code',
  'call-back-after': 'Call back in',
  'call-back-again': 'Call back again',
  'sec': 'sec.',

  'without-music': 'Without music',
  'to-add-music': 'Add music',

  'order-returned-to-work': 'Order was returned to work',
  'self-add-options': 'Additional options execution by self',
  'self-cleaning-title': 'Cleaning',
  'self-cleaning-desc': 'Options prices are set by this service',

  'to-link': 'Link',
  'to-unlink': 'Unlink',
  'notifications-in-telegram': 'Notifications in Telegram',
  'token': 'Token',
  'withdraw_disabled': 'Withdraw is temporarily disabled, you already have open withdraw order.'
}

