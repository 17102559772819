
import * as nice from '@/functions/nice-select-src.js';
export default {
    data() {
        return {
            selectValue: null,
            select_class: '',
            nice_select: null,
        }
    },

    props: [
        'value',
        'items',
        'selectClass',
        'selectPlaceholder',
        'disabled',
        'already_items',
        'forbidden_items',
    ],

    computed: {
        already_array() {
            if (!this.already_items) return []
            return this.already_items.map(item => item.id ? item.id : +item.topic)
        },
        forbidden_array() {
            if (!this.forbidden_items) return []
            return this.forbidden_items.map(item => item.id ? item.id : +item.id)
        },

    },

    mounted() {
        // console.log('select mounted'+this.selectClass)
        this.update()
    },

    methods: {
        update() {
            // console.log('nice update',this.selectClass,this.value)
            this.selectValue = this.value ? this.value : 0
            this.$nextTick(() => {
                var selectedOptions = [+this.value]
                this.select_class = this.selectClass ? this.selectClass : ''
                var placeholder = this.selectPlaceholder ? this.selectPlaceholder : '-- Выберите --'
                // console.log(selectedOptions)
                // console.log(this.nice_select)
                // this.nice_select = NiceSelect.bind(
                //     document.querySelector('.' + this.select_class), {
                //     'placeholder': placeholder,
                //     'selectedOptions': Object.values(selectedOptions)
                // })
                if (this.nice_select) this.nice_select.destroy()
                this.nice_select = nice.bind(
                    document.querySelector('.' + this.select_class), {
                    'placeholder': placeholder,
                    'selectedOptions': Object.values(selectedOptions)
                })
            })
        },
    },

    watch: {
        value: function (val, oldVal) {
            if (this.nice_select) this.nice_select.destroy()
            this.update()
        },
        selectValue: function (val, oldVal) {
            this.$emit('update', this.selectValue)
            this.$emit('input', this.selectValue)
        },
        already_array: function (val, oldVal) {
            // console.log(this.nice_select)
            if (this.nice_select) {
                if (this.nice_select.destroy()) this.update()
            }
        },
        forbidden_array: function (val, oldVal) {
            if (this.nice_select) this.nice_select.destroy()
            this.update()
        },
    },
}
