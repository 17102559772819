
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            modal_state: false,
            modal_name: 'AgentNarratorChange',
            closeable: true,
        }
    },

    methods: {
        closeModal() {
            this.$root.$emit('close-all-modals')
        },

        loginAs(id) {
            this.closeModal()
            this.login(id)
        },
        deleteNarrator(id) {
            this.$axios
                .put(`/narrator/disable/${id}`,
                    {
                        role: this.loggedInUser.role
                    },
                )
                .then((res) => {
                    this.$store.commit('updateUserData', { 'agent_narrators': res.data.agent_narrators })
                })

        },
        registerNarrator() {
            this.closeModal()
            this.login(0) //регистрируем нового диктора у агента
        },

        async login(id) {
            try {
                await this.$auth
                    .loginWith('narrator', {
                        data: {
                            agent_mode: true,
                            narrator_id: id,
                            role: 'narrator',
                            from_role: this.loggedInUser.role,
                            from_id: this.loggedInUser.id,
                        }
                    })
                    .then((res) => {
                        if (id) { //если существовал диктор, то переходим в профиль, если нет - то в заполнение регистрации
                            // this.$router.push(this.localePath('profile-settings')+'?action=refresh')
                            this.$router.push(this.localePath('profile-settings'))
                            // this.$root.$emit('refresh-narrator')
                            // document.location.href = this.localePath('profile-settings')
                        } else {
                            // this.$router.push(this.localePath('profile-settings')+'?action=register')
                            // this.$nextTick(() => {
                                this.$root.$emit('open-modal', { modal: 'register', step: 2 })
                            // })
                        }
                    })
            } catch (e) {
                // console.log(e)
                this.error = e.response.data.error
            }
        },
    },

    mounted() {
        this.$root.$on('open-modal', (data) => {
            if (data.modal == this.modal_name) {
                this.closeable = data.closeable ?? true
                this.modal_state = true
            }
        })

        this.$root.$on('close-all-modals', () => {
            this.modal_state = false
        })
    },

    beforeDestroy() {
        this.$root.$off('open-modal')
        this.$root.$off('close-all-modals')
    },

    computed: {
        ...mapGetters(['loggedInUser'])
    }
}    
