export default function (context) {//{ context, $axios, redirect, $auth }
    // console.log('axios')
    context.$axios.onRequest(config => {
        config.params = config.params || {};
        //add your params here
        // console.log(context)
        // console.log(context.i18n.locale)
        if (context.i18n.locale != 'ru') config.params['locale'] = context.i18n.locale//window.$nuxt.i18n.locale;
        return config;
    });

    context.$axios.onError(err => {
        // console.log('axios error')
        // console.log(err.response)
        // console.log(err.message)

        if (typeof err.response === 'undefined') context.redirect('/out-of-service')

        // если токен закончился
        if (err.message == 'Both token and refresh token have expired. Your request was aborted.') {
            context.app.router.push(context.localePath('index'))
            return
        }

        if (err.response.status == 401) {
            // console.log(err)
            return
        }

        if (err.response.status == 422) {
            // console.log(err)
            return
        }

        if (err.response.status == 500) {
            // console.log(err)
            $nuxt.$emit('close-all-modals')
            $nuxt.$emit('open-modal', { modal: 'notify', message: $nuxt.$t('error-happened') })
            return
        }

        context.error({ statusCode: typeof err.response !== 'undefined' ? err.response.status : 500, message: err.message });

    })
}
