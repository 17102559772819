
import { mapGetters, mapMutations } from 'vuex'
import * as ov from '@/constants/constants'

export default {
  props: [
    'mobile_menu_shown',
  ],

  data() {
    return {
      title: '',
      metainfo_title: '',
      titleTimer: null,
      menu_collapsed: false,
      referal_url: null,
    };
  },

  computed: {
    ...mapGetters(['isAuthenticated', 'loggedInUser', 'stateTimer', 'pauseTimer', 'storeOrder', 'appInfo']),

    ov() {
      return ov
    },

    userImage() {
      if (!this.isAuthenticated) return ov.DEFAULT_IMAGE
      return this.loggedInUser.image ? this.loggedInUser.image : ov.DEFAULT_IMAGE
    },

    areOrdersUnread() {
      if (!this.isAuthenticated) return false
      var res
      if (!this.$store.state.events) return false
      res = this.$store.state.events.find(item => [ov.EVENT_CREATE_ORDER_MESSAGE, ov.EVENT_DELETE_ORDER_MESSAGE, ov.EVENT_UPDATE_ORDER_MESSAGE, ov.EVENT_UPDATE_ORDER_STATUS].includes(item.type))
      return res
    },

    areTicketsUnread() {
      if (!this.isAuthenticated) return false
      if (!this.$store.state.events) return false
      return this.$store.state.events.find(item => [ov.EVENT_CREATE_TICKET_MESSAGE, ov.EVENT_DELETE_TICKET_MESSAGE, ov.EVENT_UPDATE_TICKET_MESSAGE].includes(item.type))
    },

    isDirty() {
      return this.areOrdersUnread || this.areTicketsUnread
    },

    isAgent() {
      if (!this.isAuthenticated) return false
      return !!this.loggedInUser.agent_id
    },

  },

  methods: {
    async logout() {
      //очищаем новый заказ
      this.$store.commit('toggleOrderProtectionFromCleaning', false)
      this.$store.commit('initOrder')
      //и выходим  
      await this.$auth.logout();
    },

    closeMobileMenu() {
      this.$emit('input', false)
    },

    onSidebarOverlayClick() {
      this.closeMobileMenu()
      if (this.isSidebarWrapperInner()) {
        this.$router.go(-1)
      }
    },

    ifSidebarWrapperRelative() {
      if (this.$route.path == this.localePath('become-narrator')) return ''
      if (this.$route.path == this.localePath('privacy-policy')) return ''
      if (this.$route.path == this.localePath('about')) return ''
      if (this.$route.path == this.localePath('blog')) return ''
      if (this.$route.path == this.localePath('help')) return ''
      if (this.$route.path.indexOf(this.localePath('blog')) != -1) return ''
      if (this.$route.path.indexOf(this.localePath('profile')) != -1) return ''
      if ((this.$route.params.narrator)) return '';//!!!
      if (this.$route.path == this.localePath('new-order')) return ''
      return ' sidebar-relative'
    },

    ifSidebarWrapperInner() {
      return this.isSidebarWrapperInner() ? ' inner' : '';
    },

    isSidebarWrapperInner() {
      if (
        (this.$route.params.category && this.$route.params.post)
        || (this.$route.params.narratorcomp)
        || (this.$route.params.narrator)//!!!
        || ((this.$route.path.indexOf(this.localePath('profile-support-tickets')) != -1)
          && (this.$route.params.ticket))
        || ((this.$route.path.indexOf(this.localePath('profile-orders')) != -1)
          && (this.$route.params.order))
      ) return true
      else return false
    },


    ifSidebarWithBackground() {
      if (this.$route.path == this.localePath('index')) return ' with-background';
      if (this.$route.path == this.localePath('help')) return ' with-background';
      if (this.$route.params.category && this.$route.params.post) return ' with-background';
      return '';
    },

    isBlog() {
      if (this.$route.path.indexOf(this.localePath('blog')) != -1) return ' active';
      return '';
    },

    isNarratorcomps() {
      if (this.$route.path.indexOf(this.localePath('compilations')) != -1) return ' active';
      return '';
    },

    isNarrators() {
      if (this.$route.path.indexOf(this.localePath('narrators')) != -1) return ' active';
      return '';
    },

    isProfileOrders() {
      return this.$route.path.indexOf(this.localePath('profile-orders')) != -1
    },

    isProfileSettings() {
      return this.$route.path.indexOf(this.localePath('profile-settings')) != -1
    },

    isProfileWallet() {
      return this.$route.path.indexOf(this.localePath('profile-wallet')) != -1
    },

    isProfileSupport() {
      return this.$route.path.indexOf(this.localePath('profile-support')) != -1
    },

    isClient() {
      if (!this.isAuthenticated) return false
      return !!(this.loggedInUser.role == 'client')
    },



    getAppState() {
      // console.log('getAppState')
      if (this.isAuthenticated) {
        var paramstring = (this.storeOrder.id ? '&order_id=' + this.storeOrder.id : '') + (this.$route.params.ticket ? '&ticket_id=' + this.$route.params.ticket : '')
        this.$axios
          .get('/appstate?role=' + this.loggedInUser.role + paramstring, { role: this.loggedInUser.role })
          .then((res) => {
            // console.log(res.data)
            this.$store.commit('updateUserData', res.data)
            this.$store.commit('setPauseLeft', res.data.pause_left)
            this.$store.commit('setOnPause', res.data.on_pause)
            this.$store.commit('setPauseHours', res.data.pause_hours)

            if (res.data.events) {
              this.$store.commit('update_events', res.data.events)
              this.doEvents(res.data.events)
            }
          })
      }

      //чтобы сбросился таймер мигания тайтла и чтобы вернулся заголовок, если нет изменений
      clearInterval(this.titleTimer)
      // document.title = this.title

    },

    doEvents(events) {
      var t = this
      let skip_orders = []
      // console.log('doEvents')

      if (t.$route.params.order) {
        var index = events.findLastIndex(item => (item.order_id == parseInt(t.$route.params.order)))
        // console.log(index)
        if (index + 1) {
          skip_orders.push(events[index].order_id)
          var data = JSON.parse(events[index].data)
          t.$root.$emit('update_order_status', data)
        }
      }

      // console.log(skip_orders)

      // console.log(events)

      var refresh_orders_requested_already = false

      events.forEach(function (el, i) {
        var data = JSON.parse(el.data)
        // console.log(skip_orders)

        // console.log(el.type, data)
        // console.log(t.$route)

        if (!refresh_orders_requested_already) {
          if ([ov.EVENT_CREATE_ORDER, ov.EVENT_DELETE_ORDER, ov.EVENT_UPDATE_ORDER_STATUS].includes(el.type)) {
            if (t.$route.name.includes('profile-orders')) {
              t.$root.$emit('refresh-orders', [])
              refresh_orders_requested_already = true
            }
          }
        }



        if (t.$route.params.order) {
          if ((parseInt(t.$route.params.order) == el.order_id) && !skip_orders.includes(el.order_id)) {
            // console.log(el.type, el.order_id, data)

            if (el.type == ov.EVENT_CREATE_ORDER_MESSAGE) {
              t.$store.commit('new_order_message', data)
              t.$root.$emit('new_order_message', data)
            }

            if (el.type == ov.EVENT_DELETE_ORDER_MESSAGE) {
              t.$store.commit('delete_order_message', data)
              // t.$root.$emit('delete_order_message', data)
            }

            if (el.type == ov.EVENT_UPDATE_ORDER_MESSAGE) {
              t.$store.commit('update_order_message', data)
              t.$root.$emit('update_order_message', data)
            }

            // if (el.type == ov.EVENT_UPDATE_ORDER_STATUS) {
            //   // t.$root.$emit('update_order_status', data)
            // }
          }

        }

        if (t.$route.params.ticket) {
          if ((parseInt(t.$route.params.ticket) == el.ticket_id) && (el.role == t.loggedInUser.role) && (el.user_id == t.loggedInUser.id)) {
            if (el.type == ov.EVENT_CREATE_TICKET_MESSAGE) {
              t.$root.$emit('new_ticket_message', data)
            }

            if (el.type == ov.EVENT_DELETE_TICKET_MESSAGE) {
              t.$root.$emit('delete_ticket_message', data)
            }

            if (el.type == ov.EVENT_UPDATE_TICKET_MESSAGE) {
              t.$root.$emit('update_ticket_message', data)
            }
          }
        }

      })

      //очищаем события при входе в заказ
      if (t.$route.params.order) {
        this.$store.commit('clear_order_events', t.$route.params.order)
      }

      //очищаем события при входе в тикет
      if (t.$route.params.ticket) {
        this.$store.commit('clear_ticket_events', t.$route.params.ticket)
      }

    },


    decreasePauseLeft() {
      if (this.isAuthenticated && this.loggedInUser.pause_left) {
        // this.$store.commit('setPauseLeft', this.loggedInUser.pause_left - 1)
        this.$store.commit('decreasePauseLeft')

        if (!this.loggedInUser.pause_left && this.loggedInUser.pause_hours) {
          this.$store.commit('setPauseHours', 0)
          this.$axios
            .post('/narrator/set-pause',
              {
                hours: 0,
              },
            )
            .then((res) => {
              // console.log('clear')
              this.getAppState()
            })
        }
      }

    },

    changeTitleIfDirty() {
      if (this.metainfo_title != this.$parent.$metaInfo.title) {
        this.metainfo_title = this.$parent.$metaInfo.title
        this.$nextTick(() => {
          this.title = document.title
        })
      } else {
        // console.log(this.metainfo_title + ' ' + this.$parent.$metaInfo.title)
        if (this.isDirty) {
          if (document.title != this.title) {
            document.title = this.title
          }
          else {
            document.title = '* * * * *'
          }
        }
        else {
          document.title = this.title
        }
      }

    }
  },

  mounted() {
    if (this.$store.state.referal_url) setCookie('referal_url', this.$store.state.referal_url)
    this.referal_url = getCookie('referal_url')

    // this.getAppState()

    if (!this.stateTimer) {
      const timer = setInterval(this.getAppState, 10000)
      this.$store.commit('saveStateTimer', timer)
    } else {
      clearInterval(this.stateTimer)
      const timer = setInterval(this.getAppState, 10000)
      this.$store.commit('saveStateTimer', timer)
    }

    if (!this.pauseTimer) {
      const timer2 = setInterval(this.decreasePauseLeft, 1000)
      this.$store.commit('savePauseTimer', timer2)
    } else {
      clearInterval(this.pauseTimer)
      const timer2 = setInterval(this.decreasePauseLeft, 1000)
      this.$store.commit('savePauseTimer', timer2)
    }

    if (!this.titleTimer) {
      const timer3 = setInterval(this.changeTitleIfDirty, 500)
    } else {
      clearInterval(this.titleTimer)
      const timer3 = setInterval(this.changeTitleIfDirty, 500)
    }

    this.$root.$on('getAppState', (data) => {
      this.getAppState()
    })

    this.$root.$on('refresh-orders', (data) => {
      // console.log('refresh-orders sidebar')

      this.$axios
        .get(`/${this.loggedInUser.role}/orders`)
        .then((res) => {
          var orders = res.data.orders
          this.$store.commit('updateUserData', { 'orders': orders })
        })
    })

  },


  beforeDestroy() {
    this.$root.$off('getAppState')
    this.$root.$off('refresh-orders')
  },


}
