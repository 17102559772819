import { render, staticRenderFns } from "./Narrators.vue?vue&type=template&id=6405c00a"
import script from "./Narrators.vue?vue&type=script&lang=js"
export * from "./Narrators.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Sort: require('/var/www/frontend/components/ui/Sort.vue').default,NarratorsFilter: require('/var/www/frontend/components/ui/NarratorsFilter.vue').default,Narrator: require('/var/www/frontend/components/ui/Narrator.vue').default,Narratorcomps: require('/var/www/frontend/components/ui/Narratorcomps.vue').default,Review: require('/var/www/frontend/components/ui/Review.vue').default,Demo: require('/var/www/frontend/components/ui/Demo.vue').default})
